import { Component } from "react";
import TeacherContext from "../TeacherContext";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import CenteredMessage from "../../../../components/CenteredMessage";
import { Chip, IconButton } from "@mui/material";
import Subject from "./Subject";
import RefreshIcon from "@mui/icons-material/Refresh";


export default class Subjects extends Component {

	static contextType = TeacherContext;

	state = {
		selectedClassSubject: null,
	}

	fetchClassSubjects = async () => {
		try {
			showLoading();
			await this.context.fetchClassSubjects();
		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	viewClassSubject = (classSubject) => {
		return this.updateState({ selectedClassSubject: classSubject });
	}

	closeSubjectViewer = () => {
		return this.updateState({ selectedClassSubject: null });
	}

	componentDidMount() {
		if (!this.context.classSubjects)
			this.fetchClassSubjects();
	}

	render() {

		let body;
		const { classSubjects } = this.context;

		if (!classSubjects) {
			body = <CenteredMessage
				message="Subjects not loaded"
				onRefresh={this.fetchClassSubjects}
			/>
		} else if (!classSubjects.length) {
			body = <CenteredMessage
				message="No subjects found"
				onRefresh={this.fetchClassSubjects}
			/>
		} else {
			body = <div>
				{classSubjects.map(subject => {
					return <Chip key={subject._id}
						label={`${subject.subject.name} — ${subject.class.name}`}
						color="primary"
						className="m-[5px] cursor-pointer"
						onClick={() => this.viewClassSubject(subject)}
					/>
				})}
			</div>
		}
		
		return <div className="h-full grid grid-rows-[1fr,auto]">
			<div>

				<div className="text-gray-600 text-2xl font-bold my-4">
					Class Subjects
				</div>

				{body}
			</div>
			<div className="text-right">
				<IconButton onClick={this.fetchClassSubjects}>
					<RefreshIcon />
				</IconButton>
			</div>
			{
				this.state.selectedClassSubject && <Subject
					{ ...this.state.selectedClassSubject }
					close={this.closeSubjectViewer}
				/>
			}
		</div>
	}
}