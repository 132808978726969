import Delete from "@mui/icons-material/Delete";
import Refresh from "@mui/icons-material/Refresh";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import FloatingActionButtons from "../../FloatingActionButtons";
import AddTeacherOrStudent, { MODES as AddTeacherOrStudentModes } from "../AddTeacherOrStudent";
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import Edit from "@mui/icons-material/Edit";
import AssignClass from "./AssignClass";

export default class Students extends Component {

   state = {
      students: null,
      studentCount: 0,
      page: 0,
      pageSize: 10,
      studentEditorOpen: false,
      assignClassModalOpen: false,
      selectedStudent: null
   }

   openAssignClassModal = selectedStudent => {
      return this.updateState({ assignClassModalOpen: true, selectedStudent });
   }

   closeAssignClassModal = async selectedClass => {

      const updates = { assignClassModalOpen: false, selectedStudent: null };

      if (selectedClass) {
         updates.students = this.state.students.map(item => {
            if (item._id === this.state.selectedStudent._id)
               return { ...item, class: selectedClass };
            return item;
         });
      }

      return this.updateState(updates);

   }

   openStudentEditor = () => {
      return this.updateState({ studentEditorOpen: true });
   }

   closeStudentEditor = () => {
      return this.updateState({ studentEditorOpen: false });
   }

   fetchStudents = async () => {
      try {

         showLoading();

         await this.updateState({ students: null });

         const query = new URLSearchParams({
            page_size: this.state.pageSize,
            page: this.state.page + 1,
         }).toString();

         const res = await request.get(`/api/general/students?${query}`);
         const students = res.data.students;
         const studentCount = res.data.count;

         await this.updateState({ students, studentCount });
         

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }

   deleteStudent = async (student) => {

      // ask for confirmation
      const { name, surname } = student.user;
      const question = `Are you sure you want to delete student "${name} ${surname}"?`;
      const confirm = await requestConfirmation(question);

      if (!confirm)
         return;

      try {

         // delete from server
         showLoading();
         await request.delete(`/api/admin/students/${student._id}`);

         // delete from UI
         const students = this.state.students.filter(item => item._id !== student._id);
         await this.updateState({ students });

         successToast('Deleted');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   addStudent = (student) => {
      const students = [ ...this.state.students, student ];
      return this.updateState({ students });
   }

   componentDidMount() {
      this.fetchStudents();
   }

   render() {

      let studentList;

      if (!this.state.students) {
         studentList = <TableRow>
            <TableCell colSpan={100}>
               <span className="text-gray-600">
                  Students not fetched.
               </span>
               <IconButton color="primary" onClick={this.fetchStudents}>
                  <Refresh className="text-xl"  />
               </IconButton>
            </TableCell>
         </TableRow>
      } else if (this.state.students.length === 0) {
         studentList = <TableRow>
            <TableCell colSpan={100}>
               <span className="text-gray-600">
                  Students not fetched.
               </span>
               <IconButton color="primary" onClick={this.fetchStudents}>
                  <Refresh className="text-xl"  />
               </IconButton>
            </TableCell>
         </TableRow>
      } else {
         studentList = this.state.students.map(student => {

            let cls;

				if (student.class) {
					const { name } = student.class;

					cls = <div>
						<IconButton onClick={() => this.openAssignClassModal(student) }>
							<Edit className="text-xs" />
						</IconButton>
						<span>
							{name}
						</span>
					</div>
				} else {
					cls = <span
						className="inline-block bg-[var(--primary)] text-white text-[8px] py-[2px] px-[8px] shadow-lg rounded-full cursor-pointer"
						onClick={() => this.openAssignClassModal(student)}
					>
						ASSIGN
					</span>
				}

            return <TableRow>
               <TableCell>{student.student_no}</TableCell>
               <TableCell>{student.user?.name}</TableCell>
               <TableCell>{student.user?.surname}</TableCell>
               <TableCell>{student.user?.gender}</TableCell>
               <TableCell>{student.user?.email}</TableCell>
               <TableCell>{student.user?.phone}</TableCell>
               <TableCell>{cls}</TableCell>
               <TableCell>
                  <IconButton color="error" onClick={() => this.deleteStudent(student) }>
                     <Delete />
                  </IconButton>
               </TableCell>
            </TableRow>
         })
      }

      return <>
         <div className="relative">
            <TableContainer component={Paper} className="h-full">
               <Table size="small">
                  <TableHead>
                     <TableRow>
                        <TableCell>STUDENT NO</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>SURNAME</TableCell>
                        <TableCell>GENDER</TableCell>
                        <TableCell>EMAIL</TableCell>
                        <TableCell>PHONE</TableCell>
                        <TableCell>CLASS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {studentList}
                  </TableBody>
               </Table>

               <TablePagination
                  page={this.state.page}
                  count={this.state.studentCount}
                  labelRowsPerPage="Students per page"
                  rowsPerPage={this.state.pageSize}
                  onPageChange={
                     async (_, page) => {
                        await this.updateState({ page });
                        this.fetchStudents();
                     }
                  }
                  onRowsPerPageChange={
                     async (event) => {
                        await this.updateState({ page: 0, pageSize: event.target.value });
                        this.fetchStudents();
                     }
                  }
               />
            </TableContainer>

            <FloatingActionButtons
               expandingDirection="up"
               onRefresh={this.fetchStudents}
               onAdd={this.openStudentEditor}
            />
         </div>

         {
            this.state.studentEditorOpen && <AddTeacherOrStudent
               mode={AddTeacherOrStudentModes.STUDENT}
               close={this.closeStudentEditor}
               addToList={this.addStudent}
            />
         }

         {
            this.state.assignClassModalOpen && <AssignClass
               student={this.state.selectedStudent}
               close={this.closeAssignClassModal}
            />
         }
         
      </>
   }
}