import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../loading";
import request from "../../../request";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import Thumbnail from "../HomeworkTest/Thumbnail";
import { Fab, Pagination } from "@mui/material";
import HomeworkEditor from "../HomeworkTest/HomeworkEditor";
import TestEditor from "./TestEditor";
import { connect } from "react-redux";
import TermNavigator from "../../../components/TermNavigator";

const PAGE_SIZE = 10;

class HomeworkTestsUnconnected extends Component {

	state = {
		data: null,
		page: 1,
		pageCount: 0,
		editorOpen: false,
		term: {
			year: new Date().getFullYear(),
			term: 1,
		}
	}

	openEditor = () => {
		return this.updateState({ editorOpen: true });
	}

	closeEditor = (data) => {

		const update = { editorOpen: false };

		if (data)
			update.data = [ data, ...(this.state.data || [])];

		return this.updateState(update);
	}

	isHomework = () => {
		return this.props.entityType === HomeworkTests.ENTITY_TYPES.HOMEWORK;
	}

	fetchData = async () => {
		try {

			showLoading();

			const {
				page,
				term: { year, term }
			} = this.state;

			const params = {
				page,
				limit: PAGE_SIZE,
				year,
				term,
			};

			const testsOrHomework = this.isHomework() ? 'homework' : 'tests';
			const res = await request.get(`/api/teacher/${testsOrHomework}`, { params });
			const data = res.data.homework || res.data.tests;
			
			const pageCount = Math.ceil(res.data.count / PAGE_SIZE);
			this.setState({ data, pageCount });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	onTermChange = async (term) => {
		await this.updateState({ term });
		this.fetchData();
	}

	async componentDidMount() {

		if (this.props.currentTerm)
			await this.updateState({ term: this.props.currentTerm });
		
		this.fetchData();
		
	}

	render() {
		
		let body;
		const data = this.state.data;

		if (data === null) {
			body = <div className="flex justify-center items-center h-full">
				<div className="text-center">
					<p className="text-lg text-gray-600">Data not loaded</p>
					<IconButton onClick={this.fetchData}>
						<RefreshIcon color="primary" />
					</IconButton>
				</div>
			</div>;
		} else if (data.length === 0) {
			body = <div className="flex justify-center items-center h-full">
				<div className="text-lg text-gray-600">
					No {this.isHomework() ? 'homework' : 'tests'} found
				</div>
			</div>;
		} else {
			body = <div>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
					{data.map(item => {
						return <Thumbnail
							key={item._id}
							{ ...item}
							isHomework={this.isHomework()}
							onDelete={this.fetchData}
							allowEditing
						/>;
					})}
				</div>
			</div>
		}

		const Editor = this.isHomework() ? HomeworkEditor : TestEditor;

		return <div className="h-full p-4">


			<div className="h-full grid grid-rows-[1fr,auto]">
				{body}
			
				<div className="grid grid-cols-[1fr,auto,auto] gap-4">
					<div>
						<Pagination
							page={this.state.page}
							count={this.state.pageCount}
							onChange={
								async (_, page) => {
									await this.updateState({ page });
									this.fetchData();
								}
							}
						/>
					</div>

					<TermNavigator
						onChange={this.onTermChange}
						term={this.state.term}
						max={this.props.currentTerm}
					/>

					<div>
						<IconButton onClick={this.fetchData}>
							<RefreshIcon />
						</IconButton>
						<Fab color="primary" size="small" onClick={this.openEditor}>
							<AddIcon />
						</Fab>
					</div>
				</div>

			</div>

			{
				this.state.editorOpen && <Editor
					close={this.closeEditor}
					mode={Editor.MODES.ADD}
				/>
			}
		</div>
	}
}


const mapStateToProps = state => ({ currentTerm: state.user?.school?.current_term });

const HomeworkTests = connect(mapStateToProps)(HomeworkTestsUnconnected);

HomeworkTests.ENTITY_TYPES = {
	HOMEWORK: 'homework',
	TEST: 'test',
}

export default HomeworkTests;