import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Component } from "react";
import { hideLoading, showLoading } from "../../../../../loading";
import swal from "sweetalert";
import request from "../../../../../request";
import CenteredMessage from "../../../../../components/CenteredMessage";
import EmptyState from "../../../../../components/EmptyState";


export default class StudentMarks extends Component {

	state = {
		marks: null,
	}

	fetchMarks = async () => {
		try {

			showLoading();

			const res = await request.get(`/api/teacher/gradings/${this.props._id}/marks`);
			const { marks } = res.data;
			this.setState({ marks });

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		this.fetchMarks(); // TODO: UNCOMMENT
	}

	render() {

		let dialogContent;
		const { marks } = this.state;

		if (!marks) {
			dialogContent = <CenteredMessage
				message={"Failed to fetch marks"}
				onRefresh={this.fetchMarks}
			/>
		} else if (marks.length === 0) {
			dialogContent = <EmptyState
				title="No marks available"
				subtitle="Please go and record marks for the graded tests"
			/>
		} else {
			dialogContent = marks.map(item => {

				const {
					student: {
						user: { name, surname, },
						class: { name: className }
					},
					mark,
				} = item

				return <div className="p-3">
					<div className="grid grid-cols-[1fr,auto] text-gray-600">
						<div>
							<div className="capitalize text-lg font-bold">{name} {surname}</div>
							<div className="text-sm">
								Class: {className}
							</div>
						</div>
						<div className={`flex items-center px-2 font-bold ${mark >= 50 ? 'text-green-600' : 'text-red-600'}`}>
							{mark}%
						</div>
					</div>
					<Divider className="mt-3" />
				</div>
			});
		}

		return <Dialog open>
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent dividers>
				<div className="w-[500px] max-w-[100%] h-[500px] overflow-x-hidden overflow-y-auto">
					{dialogContent}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.props.close}>
					CLOSE
				</Button>
			</DialogActions>
		</Dialog>
	}
}