
import { Loading } from 'notiflix/build/notiflix-loading-aio';


function showLoading(text='') {
   Loading.pulse(text, {
      backgroundColor: 'white',
      svgColor: 'var(--primary)'
   });
}

function hideLoading() {
   Loading.remove();
}

export {
   hideLoading,
   showLoading
}