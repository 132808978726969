import { Button } from "@mui/material";
import { Link } from 'react-router-dom';


export default function SignupAndLogin({ closeMobileNav }) {

   if (typeof closeMobileNav !== 'function')
      closeMobileNav = () => { }
   
   return <div className='grid grid-cols-2 gap-2'>
      <div>
         <Button variant='contained' className='rounded-lg w-full' component={Link} to="/signup" onClick={closeMobileNav}>
            Sign Up
         </Button>
      </div>
      <div>
         <Button variant='outlined' className='rounded-lg w-full' component={Link} to="/login" onClick={closeMobileNav}>
            Login
         </Button>
      </div>
   </div>
}