import { Component } from "react";
import CenteredMessage from "../../components/CenteredMessage";
import EmptyState from '../../components/EmptyState';
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { hideLoading, showLoading } from "../../loading";
import { connect } from "react-redux";
import request from "../../request";
import swal from "sweetalert";
import PillLabel from "../../components/PillLabel";
import TermNavigator from "../../components/TermNavigator";


class ReportCardUnconnected extends Component {

	state = {
		term: {
			year: new Date().getFullYear(),
			term: 1,
		},
		report: null
	}

	fetchReport = async () => {

		try {
		
			showLoading();

			const studentId = this.props.studentId || 'null';
			const params = { ...this.state.term }

			const res = await request.get(`/api/general/students/${studentId}/report`, { params });
			const report = res.data;
			this.setState({ report });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	onTermChange = async (term) => {
		await this.updateState({ term });
		this.fetchReport();
	}

	componentDidMount() {
		this.fetchReport();

		const { currentTerm } = this.props;

		if (currentTerm)
			this.setState({ term: currentTerm });
	}

	render() {
		
		const { report } = this.state;
		const marks = report?.class_subjects;
		let jsx;

		if (!report) {
			jsx = <CenteredMessage
				message={"Failed to load the report"}
				onRefresh={this.fetchReport}
			/> 
		} else if (!marks) {
			jsx = <CenteredMessage
				message={`Report for Term ${this.state.term.year}–${this.state.term.term} not available`}
				onRefresh={this.fetchReport}
			/>
		} else if (marks.length  === 0) {
			jsx = <EmptyState
				title="No marks"
			/>
		} else {
			jsx = <div>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
					{
						marks.map(item => {

							const { subject: { name: subject }, mark } = item;

							let borderColorClass, markBgColorClass;

							if (mark >= 50) {
								borderColorClass = "border-green-500";
								markBgColorClass = "bg-green-500";
							} else {
								borderColorClass = "border-red-500";
								markBgColorClass = "bg-red-500";
							}

							return <div className={`grid grid-cols-[1fr,auto] text-lg font-bold border-b-solid ${borderColorClass} border-b-[4px]`}>
								<div className="px-4 py-2 text-gray-600">{subject}</div>
								<div
									className={`aspect-square flex items-center justify-center text-white ${markBgColorClass}`}
								>
									{mark}
								</div>
							</div>
						})
					}
				</div>
			</div>
		}

		return <div className="h-full grid grid-rows-[auto,1fr,auto]">
			<div>
				<PillLabel>
					{this.state.term.year}–{this.state.term.term} Report
				</PillLabel>
			</div>
			{jsx}
			<div className="flex justify-end">
				<TermNavigator
					term={this.state.term}
					onChange={this.onTermChange}
					max={this.props.currentTerm}
				/>
				<IconButton onClick={this.fetchReport}>
					<RefreshIcon />
				</IconButton>
			</div>
		</div>
	}
}

const mapStateToProps = state => {
	const currentTerm = state.user?.school?.current_term;
	const props = {};

	if (currentTerm)
		props.currentTerm = currentTerm;

	return props;
	
};
const ReportCard = connect(mapStateToProps)(ReportCardUnconnected);

export default ReportCard;