

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import React from 'react';
import swal from 'sweetalert';
import { showLoading, hideLoading } from '../../../../loading';
import request from '../../../../request';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SubjectPicker from './SubjectPicker';
import { errorToast, successToast } from '../../../../toast';
import { requestConfirmation } from '../../../../utils';
import ExpandIcon from '@mui/icons-material/ExpandMore'
import SearchTeacherOrStudent from '../SearchTeacherOrStudent';
import Send from '@mui/icons-material/Send';

class SubjectManager extends React.Component {

	state = {
		classSubjects: null,
		showingSubjectPicker: false,
		classSubjectBeingAssignedATeacher: null,
		teacherSelectedForAssignment: null
	}

	openSubjectPicker = () => {
		return this.updateState({ showingSubjectPicker: true });
	}

	closeSubjectPicker = () => {
		return this.updateState({ showingSubjectPicker: false });
	}

	onSubjectSelected = async (subject) => {

		try {

			showLoading();

			const classId = this.props.class._id;
			const data = { subject: subject._id };
			const res = await request.post(`/api/admin/classes/${classId}/subjects`, data);

			const _id = res.data._id;

			const classSubjects = [
				...this.state.classSubjects,
				{
					_id,
					subject,
				}
			];

			await this.updateState({ classSubjects });

			successToast('Subject added to class');
			

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	removeSubject = async (classSubject) => {

		const _class = this.props.class;

		const question = `Are you sure you want to remove subject "${classSubject.subject.name}" from class "${_class.name}"?`;
		const confirm = await requestConfirmation(question);

		if (!confirm)
			return;

		try {

			showLoading();

			const classId = _class._id;
			const classSubjectId = classSubject._id;
			await request.delete(`/api/admin/classes/${classId}/subjects/${classSubjectId}`);

			const classSubjects = this.state.classSubjects.filter(item => classSubjectId !== item._id);
			await this.updateState({ classSubjects });


		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	fetchClassSubjects = async () => {

		try {

			showLoading();

			const classId = this.props.class._id;
			const res = await request.get(`/api/admin/classes/${classId}/subjects`);
			const classSubjects = res.data;

			await this.updateState({ classSubjects });

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading();
		}
		
	}

	submitTeacherAssign = async () => {

		const teacher = this.state.teacherSelectedForAssignment;

		if (!teacher)
			return errorToast('Select teacher');

		try {

			const classSubject = this.state.classSubjectBeingAssignedATeacher;
			const url = `/api/admin/teachers/${teacher._id}/class-subjects`;
			const data = [ classSubject._id ];

			await request.post(url, data);

			const classSubjects = this.state.classSubjects.map(item => {
				if (item._id !== classSubject._id)
					return item;
				return {
					...item,
					teacher,
				}
			});

			await this.updateState({
				classSubjects,
				classSubjectBeingAssignedATeacher: null,
				teacherSelectedForAssignment: null
			});

			successToast('Teacher assigned');

		} catch (err) {
			swal(String(err));
		}
	}

	componentDidMount() {
		this.fetchClassSubjects();
	}

	render() {

		let subjects;

		const addSubjectButton = <IconButton onClick={this.openSubjectPicker} color='primary'>
			<AddIcon />
		</IconButton>

		if (!this.state.classSubjects) {
			subjects = <p className='text-gray-600'>
				Subjects not loaded <IconButton onClick={this.fetchClassSubjects} color='primary'>
					<RefreshIcon className='text-lg' />
				</IconButton>
			</p>
		} else if (this.state.classSubjects.length === 0) {
			subjects = <div className="grid grid-cols-[1fr,auto]">
				<p className='text-gray-600'>No subjects</p>
				{addSubjectButton}
			</div>
		} else {
			subjects = <div>
				<div className='grid grid-cols-[1fr,auto]'>
					<div className='font-bold'>
						Subjects
					</div>
					{addSubjectButton}
				</div>
				{
					this.state.classSubjects.map(item => {

						const { teacher, subject } = item;

						let details;
						const { classSubjectBeingAssignedATeacher } = this.state;

						if (classSubjectBeingAssignedATeacher && classSubjectBeingAssignedATeacher === item) {
							details = <div className='grid grid-cols-[1fr,auto,auto]'>
								<SearchTeacherOrStudent
									label="Assign teacher"
									mode={SearchTeacherOrStudent.MODES.STUDENT}
									onSelect={teacherSelectedForAssignment => this.updateState({ teacherSelectedForAssignment })}
								/>
								<div>
									<IconButton color='primary' onClick={this.submitTeacherAssign}>
										<Send />
									</IconButton>
								</div>
								<div>
									<IconButton color='error' onClick={() => this.updateState({ classSubjectBeingAssignedATeacher: null })}>
										<CancelIcon />
									</IconButton>
								</div>
							</div>
						} else if (teacher?.user) {
							details = <>
								Taught by <b>{teacher.user.name} {teacher.user.surname}</b>
							</>
						} else {
							details = 'No teacher assigned'
						}

						let btnAssignTeacher;
						const assignTeacherCaption = teacher ? 'Change Teacher' : 'Assign Teacher';

						if (!classSubjectBeingAssignedATeacher || classSubjectBeingAssignedATeacher !== item) {
							btnAssignTeacher = <Button
								onClick={() => this.updateState({ classSubjectBeingAssignedATeacher: item })}
							>
								{assignTeacherCaption}
							</Button>
						}

						return <Accordion>
							<AccordionSummary expandIcon={<ExpandIcon />}>
								<span className='text-lg text-gray-600'>
									{subject?.name || '(invalid subject'}
								</span>
							</AccordionSummary>
							<AccordionDetails>
							<span className='text-sm text-gray-500'>
								{details}
							</span>
							</AccordionDetails>
							<AccordionActions>
								{btnAssignTeacher}
								<IconButton
									onClick={ () => this.removeSubject(item) }
									color='error'
								>
									<DeleteIcon />
								</IconButton>
							</AccordionActions>
							
						</Accordion>
					})
				}
			</div>
		}

		return <Dialog open>
			<DialogTitle>Manage subjects</DialogTitle>

			<DialogContent dividers>
				<div className='md:w-[400px]'>
					{subjects}
					{
						this.state.showingSubjectPicker && <>
							<Divider className='my-2' />
							<div className='p-2 shadow'>
								<div className='grid grid-cols-[1fr,auto]'>
									<div className='h-full flex items-center'>
										<div className='font-semibold text-lg'>Add subject</div>
									</div>
									<div className='h-full flex items-center'>
										<IconButton color='error' onClick={this.closeSubjectPicker}>
											<CancelIcon />
										</IconButton>
									</div>
								</div>

								<Divider className='mb-2' />

								<SubjectPicker
									onSelect={this.onSubjectSelected}
								/>
							</div>
						</>
					}
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					size='small'
					onClick={this.props.close}
				>
					CLOSE
			</Button>
			</DialogActions>
		</Dialog>
	}
}

export default SubjectManager;