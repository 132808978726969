
import ExpandIcon from '@mui/icons-material/ChevronRight';

export default function ChildThumbnail(props) {

	const { user, school, outstanding_fees, onClick } = props;

	return <div className="p-2 hover:bg-gray-100 hover:p-3 cursor-pointer grid grid-cols-[1fr,auto]" onClick={onClick}>
		<div className="">
			<div className="capitalize text-lg text-gray-600 font-bold">
				{user.name} {user.surname}
			</div>
			<div className="text-sm text-gray-600">
				{school.name}, { school.address }
			</div>
			<div className="text-sm text-gray-600">
				Fees: <span className={`${outstanding_fees > 0 ? 'text-red-600' : 'text-green-600'} font-bold`}>
					{ outstanding_fees <= 0 ? 'Paid' : `Outstanding (${outstanding_fees.toFixed(2)})` }
				</span>
			</div>
		</div>
		<div className="flex items-center text-gray-400">
			<ExpandIcon />
		</div>
	</div>
}