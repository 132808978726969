
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ClassPicker from '../ClassPicker';
import { errorToast, successToast } from '../../../../toast';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../../../loading';
import request from '../../../../request';

class AssignClass extends Component {

	state = {
		selectedClass: null
	}

	submit = async () => {

		const { selectedClass } = this.state;

		if (!selectedClass)
			return errorToast('Select class');

		try {

			showLoading();

			const data = { class: selectedClass._id };
			await request.patch(`/api/admin/students/${this.props.student._id}`, data);

			this.props.close(selectedClass);
			successToast('Added to class');

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	render() {
		const { name, surname } = this.props.student.user;

		return <Dialog open>
			<DialogTitle>Add {name} {surname} to class</DialogTitle>

			<DialogContent dividers>
				<ClassPicker
					onSelect={selectedClass => this.updateState({ selectedClass })}
				/>
			</DialogContent>

			<DialogActions>
				<Button variant='contained' size='small' onClick={this.submit}>
					SAVE
				</Button>
				<Button variant='text' size='small' onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}

AssignClass.propTypes = {
	close: PropTypes.func.isRequired,
	student: PropTypes.object.isRequired,
};

export default AssignClass;