
import { getClassLevelLabel } from '../../../../../../../shared/utils';
import { Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Component } from 'react';
import { requestConfirmation } from '../../../../../utils';
import { hideLoading, showLoading } from '../../../../../loading';
import request from '../../../../../request';
import { successToast } from '../../../../../toast';
import swal from 'sweetalert';
import Editor from '../Editor';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StudentMarks from './StudentMarks';


export default class Grading extends Component {

	state = {
		editorOpen: false,
		showingStudentMarks: false,
	}

	openEditor = async () => {
		this.setState({ editorOpen: true });
	}

	closeEditor = async data => {
		await this.updateState({ editorOpen: false });
		this.props.onEdit(data);
	}

	delete = async () => {
		// confirm
		const question = `Delete "${this.getTitle()}"?`;
		const confirm = await requestConfirmation(question);

		if (!confirm)
			return;

		// delete
		try {

			showLoading();

			const _id = this.props._id;
			await request.delete(`/api/teacher/gradings/${_id}`);

			successToast('Deleted');
			this.props.onDelete(_id);

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	getTitle() {
		const { class_subjects } = this.props.data;
		const { school } = this.props;
		const gradeOrForm = getClassLevelLabel(school?.type);
		const subject = class_subjects[0].subject
		const level = class_subjects[0].class.level
		const classNames = class_subjects.map(item => item.class.name).join(', ');
		return `${subject.name} – ${gradeOrForm} ${level} (${classNames})`
	}

	render() {

		const { weights } = this.props.data;

		return <div className='pt-4 px-4'>
			<div className="text-gray-600 grid grid-cols-[1fr,auto] gap-4">
				<div>
					<div className="text-lg font-bold">
						{this.getTitle()}
					</div>
					<div className='text-sm'>
						{
							weights
								.map(w =>  `${w.test.name} (${w.weight}%)`)
								.join(' • ')
						}
					</div>
				</div>
				<div>
					<IconButton onClick={() => this.setState({ showingStudentMarks: true })}>
						<AssessmentIcon />
					</IconButton>
					{
						this.state.showingStudentMarks && <StudentMarks
							_id={this.props.data._id}
							title={this.getTitle()}
							close={() => this.setState({ showingStudentMarks: false })}
						/>
					}
					<IconButton onClick={this.openEditor}>
						<EditIcon />
					</IconButton>
					{
						this.state.editorOpen && <Editor
							close={this.closeEditor}
							data={this.props.data}
						/>
					}
					<IconButton color='error' onClick={this.delete}>
						<DeleteIcon />
					</IconButton>
				</div>
			</div>
			
			<Divider className='my-4' />

		</div>
	}
}