import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PMessage from "./PMessage";
import TabPanel from "./TabPanel";

export default function Students({ students, loading, error, onRefresh, onDismissError }) {

	let studentsJSX;

	if (students) {
		if (students.length > 0) {
			studentsJSX = <Table>
				<TableHead>
					<TableRow>
						<TableCell>STUDENT NO</TableCell>
						<TableCell>NAME</TableCell>
						<TableCell>SURNAME</TableCell>
						<TableCell>GENDER</TableCell>
						<TableCell>EMAIL</TableCell>
						<TableCell>PHONE</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						students.map(student => {
							return <TableRow>
								<TableCell>{student.student_no}</TableCell>
								<TableCell>{student.user?.name}</TableCell>
								<TableCell>{student.user?.surname}</TableCell>
								<TableCell>{student.user?.gender}</TableCell>
								<TableCell>{student.user?.email}</TableCell>
								<TableCell>{student.user?.phone}</TableCell>
							</TableRow>
						})
					}
				</TableBody>
			</Table>
		} else {
			studentsJSX = <p className="text-gray-600 text-lg">
				No students in this class
			</p>
		}
	} else {
		studentsJSX = <PMessage>
			Students not loaded
		</PMessage>
	}

	return <TabPanel
		heading="Students"
		body={studentsJSX}
		loading={loading}
		error={error}
		onRefresh={onRefresh}
		onDismissError={onDismissError}
		loaded={!!students}
	/>
	
}