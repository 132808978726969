
import axios from 'axios';
import initAuth from '@xavisoft/auth/frontend';


class AxiosError extends Error {

   toString() {
      return this.message;
   }

   constructor(msg, status) {
      super(msg);
      this.status = status;
   }
}

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://classmate.xavisoft.co.zw';

const request = axios.create({
   baseURL,
});


initAuth({
   axios: request,
   refreshRoute: '/api/auth/login',
});

request.interceptors.response.use(null, err => {

   if (err && err.response) {
      const msg = typeof err.response.data === 'string' ? err.response.data : err.response.statusText;;
      err = new AxiosError(msg, err.response.status);
   } else if (err.code === 'ERR_NETWORK') {
      err = new AxiosError('Network Error', 0);
   }

   throw err;
});

request.interceptors.response.use(null, err => {

   if (err.status === 401) {
      window.App.redirect('/login');
   }

   throw err;
   
});


export default request;
