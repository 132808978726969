import { Button, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import ClassEditor from "./ClassEditor";
import SchoolContext from '../SchoolContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import AssignTeacher from "./AssignTeacher";
import SubjectManager from "./SubjectManager";
import FloatingActionButtons from "../../FloatingActionButtons";
import actions from "../../../../redux/actions";
import { connect } from "react-redux";


class ClassesUnconnected extends Component {

	static contextType = SchoolContext;

	state = {
		classEditorMode: null,
		selectedClass: null,
		assignTeacherModalOpen: false,
		subjectManagerOpen: false,
	}

	fetchClasses = async () => {

		try {

			showLoading();

			await actions.fetchClasses();

		} catch (err) {
			swal(err.toString());
		} finally {
			hideLoading();
		}
	}

	openClassEditor = (mode, selectedClass=null) => {
		return this.updateState({ classEditorMode: mode, selectedClass })
	}

	closeClassEditor = () => {
		return this.updateState({ classEditorMode: null, selectedClass: null })
	}

	openAssignTeacherModal = (selectedClass) => {
		return this.updateState({ assignTeacherModalOpen: true, selectedClass })
	}

	closeAssignTeacherModal = () => {
		return this.updateState({ assignTeacherModalOpen: false, selectedClass: null })
	}

	openSubjectManager = (selectedClass) => {
		return this.updateState({ subjectManagerOpen: true, selectedClass })
	}

	closeSubjectManager = () => {
		return this.updateState({ subjectManagerOpen: false, selectedClass: null })
	}

	deleteClass = async (cls) => {

		const question = `Are you sure you want to delete class "${cls.name}"?`;
		const confirm = await requestConfirmation(question);

		if (!confirm)
			return;

		try {

			showLoading();

			await request.delete(`/api/admin/classes/${cls._id}`);
			actions.deleteClass(cls._id);

			successToast("Deleted");

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		if (!this.props.classes)
			this.fetchClasses();
	}

	render() {

		let classes;

		if (!this.props.classes) {
			classes = <TableRow>
				<TableCell colSpan={100}>
					<div className="my-[200px] flex justify-center">
						<div>
							<p>Classes not loaded</p>
							<Button onClick={this.fetchClasses}>
								RETRY
							</Button>
						</div>
					</div>
				</TableCell>
			</TableRow>
		} else if (this.props.classes.length === 0) {
			classes = <TableRow><TableCell colSpan={100}>No classes found</TableCell></TableRow>
		} else {
			classes = this.props.classes.map(cls => {

				let teacher;

				if (cls.teacher) {
					const { name, surname } = cls.teacher.user;

					teacher = <div>
						<IconButton onClick={() => this.openAssignTeacherModal(cls) }>
							<EditIcon className="text-xs" />
						</IconButton>
						<span>
							{name} {surname}
						</span>
					</div>
				} else {
					teacher = <span
						className="inline-block bg-[var(--primary)] text-white text-[8px] py-[2px] px-[8px] shadow-lg rounded-full cursor-pointer"
						onClick={() => this.openAssignTeacherModal(cls)}
					>
						ASSIGN
					</span>
				}

				return <TableRow key={cls._id}>
					<TableCell>{cls.name}</TableCell>
					<TableCell>{cls.level}</TableCell>
					<TableCell>{cls.students}</TableCell>
					<TableCell>{teacher}</TableCell>
					<TableCell>
						<Link className="cursor-pointer" onClick={() => this.openSubjectManager(cls)}>
							Manage
						</Link>
					</TableCell>
					<TableCell>
						<IconButton
							onClick={ () => this.openClassEditor(ClassEditor.MODES.EDIT, cls) }
						>
							<EditIcon className="text-sm" />
						</IconButton>
						<IconButton
							onClick={() => this.deleteClass(cls) }
						>
							<DeleteIcon className="text-sm text-red-600" />
						</IconButton>
					</TableCell>
				</TableRow>
			})
		}

		let fab; 

		if (this.props.classes) {
			fab  = <>
				<FloatingActionButtons
					onAdd={() => this.openClassEditor(ClassEditor.MODES.ADD)}
					onRefresh={this.fetchClasses}
				/>

				{
					this.state.classEditorMode && <ClassEditor
						mode={this.state.classEditorMode}
						class={this.state.selectedClass}
						schoolType={this.context.school.type}
						close={this.closeClassEditor}
					/>
				}

			</>
		}

		return (
			<>
				<div className="h-full relative">
					<TableContainer component={Paper}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Level</TableCell>
									<TableCell>Students</TableCell>
									<TableCell>Teacher</TableCell>
									<TableCell>Subjects</TableCell>
									<TableCell>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{classes}
							</TableBody>
						</Table>
					</TableContainer>
					{fab}
					{
						this.state.assignTeacherModalOpen && <AssignTeacher
							class={this.state.selectedClass}
							close={this.closeAssignTeacherModal}
						/>
					}
					{
						this.state.subjectManagerOpen && <SubjectManager
							class={this.state.selectedClass}
							close={this.closeSubjectManager}
						/>
					}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({ classes: state.classes });
const Classes = connect(mapStateToProps)(ClassesUnconnected);
export default Classes;