
import React from 'react';
import RecordAttendanceIcon from '@mui/icons-material/NoteAdd';
import ViewTimetableicon from '@mui/icons-material/Today';
import { Button, Divider } from '@mui/material';
import RecordAttendance from './RecordAttendance';
import HomeworkEditor from '../../HomeworkTest/HomeworkEditor';
import TestEditor from '../TestEditor';
import { errorToast } from '../../../../toast';

class Dashboard extends React.Component {

	state = {
		recordAttendanceModalOpen: false,
		homeworkEditorOpen: false,
		testEditorOpen: false,
	}

	openRecordAttendanceModal = () => {
		return this.updateState({ recordAttendanceModalOpen: true })
	}

	closeRecordAttendanceModal = () => {
		return this.updateState({ recordAttendanceModalOpen: false })
	}

	openHomeworkEditor = () => {
		return this.updateState({ homeworkEditorOpen: true })
	}

	closeHomeworkEditor = () => {
		return this.updateState({ homeworkEditorOpen: false })
	}

	openTestEditor = () => {
		return this.updateState({ testEditorOpen: true })
	}

	closeTestEditor = () => {
		return this.updateState({ testEditorOpen: false })
	}

	getActions() {
		return [
			{
				icon: RecordAttendanceIcon,
				title: 'Record attendance',
				description: 'Record today\'s attendances of your students',
				caption: 'Record',
				action: this.openRecordAttendanceModal,
			},
			{
				icon: ViewTimetableicon,
				title: 'View Timetable',
				description: 'View your timetable to see when you are busy/free',
				caption: 'View',
				action: () => errorToast('Feature coming soon'),
			},
		];
	}

	getShortcuts() {
		return [
			{
				icon: RecordAttendanceIcon,
				title: 'Add homework',
				description: 'Give your students some homework',
				caption: 'Add',
				action: this.openHomeworkEditor
			},
			{
				icon: RecordAttendanceIcon,
				title: 'Add test',
				description: 'Record a test',
				caption: 'Add',
				action: this.openTestEditor
			},
		];
	}

	render() {
		return <div className='h-full p-4'>
			
			<Section
				heading="Actions"
				items={this.getActions()}
			/>

			<div className='h-10' />

			<Section
				heading="Shortcuts"
				items={this.getShortcuts()}
			/>

			{
				this.state.recordAttendanceModalOpen && <RecordAttendance
					close={this.closeRecordAttendanceModal}
				/>
			}

			{
				this.state.homeworkEditorOpen && <HomeworkEditor
					close={this.closeHomeworkEditor}
					mode={HomeworkEditor.MODES.ADD}
				/>
			}

			{
				this.state.testEditorOpen && <TestEditor
					close={this.closeTestEditor}
					mode={TestEditor.MODES.ADD}
				/>
			}

		</div>
	}
}

function Heading(props) {
	return <div className='font-extrabold text-xl text-gray-500'>
		{props.children}
	</div>
}

function Action(props) {
	return <div className='p-8 bg-white rounded-lg mt-8'>
		<div className='text-center'>
			<props.icon
				className="text-7xl text-[var(--primary)]"
			/>
			<div className='font-bold text-gray-600 text-lg mt-2'>
				{props.title}
			</div>
			<div className='text-sm text-gray-600 mt-2'>
				{props.description}
			</div>
			<Button onClick={props.action} variant='contained' color='secondary' size='small' className='mt-2'>
				{props.caption}
			</Button>
		</div>
	</div>
}

function Section(props) {
	return <div className='bg-[#f9f9f9] rounded p-8 shadow'>

		<Heading>{props.heading}</Heading>

		<Divider className='my-2' />

		<div className='md:grid md:grid-cols-3 lg:grid-cols-4 gap-8'>
			{
				props.items.map(item => <Action {...item} />)
			}
		</div>
	</div>
}

export default Dashboard;