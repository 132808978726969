import { Component } from "react";
import ChildrenIcon from '@mui/icons-material/ChildCare';
import HomeworkIcon from '@mui/icons-material/Assignment';
import Children from "./Children";
import Homework from "./Homework";


const SIDEBAR_VALUES = {
	CHILDREN: 'children',
	HOMEWORK: 'homework',
}


export default class ParentPortal extends Component {

	static title = 'Parent Portal'

	static sidebarItems = [
		{
			caption: "Children",
			icon: ChildrenIcon,
			value: SIDEBAR_VALUES.CHILDREN
		},
		{
			caption: "Homework",
			icon: HomeworkIcon,
			value: SIDEBAR_VALUES.HOMEWORK
		},
	]

	render() {

		switch (this.props.sidebarValue) {

			case SIDEBAR_VALUES.HOMEWORK:
				return <Homework />

			default:
				return <Children />
		}
	}
}