import { Button, IconButton } from "@mui/material";
import InstallIcon from "@mui/icons-material/InstallDesktop";
import CloseIcon from "@mui/icons-material/Close";
import { Component } from "react";
import logger from "../logger";

export default class InstallPrompt extends Component {

	state = {
		installPrompt: null
	}

	install = async () => {
		try {
			const { installPrompt } = this.state;
			if (!installPrompt)
				return;

			await installPrompt.prompt();
			this.setState({ installPrompt: null });
		} catch (err) {
			logger.error(err);
		}
	}

	componentDidMount() {
		window.addEventListener('beforeinstallprompt', e => {
			e.preventDefault();
			this.setState({ installPrompt: e });
		});

		window.addEventListener('appinstalled', e => {
			this.setState({ installPrompt: null });
		});
	}

	render() {

		if (!this.state.installPrompt)
			return <></>;

		return <div className="fixed bottom-0 left-0 right-0 bg-[var(--primary)] grid grid-cols-[1fr,auto] z-40">
			<div className="p-3 text-right">
				<Button className="text-white" startIcon={<InstallIcon />} onClick={this.install}>
					INSTALL APP
				</Button>
			</div>
			<div className="border-l-solid border-l-[#ccc] border-l-[1px] flex items-center justify-center">
				<div className="aspect-square h-full]">
					<IconButton className="text-gray-300" size="large" onClick={() => this.setState({ installPrompt: null })}>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
		</div>
	}
}