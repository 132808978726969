
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import request from '../../../../request';
import { errorToast, successToast } from '../../../../toast';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../../../loading';
import SearchTeacherOrStudent from '../SearchTeacherOrStudent';
import actions from '../../../../redux/actions';

class AssignTeacher extends React.Component {

	state = {
		teacher: null,
	}

	submit = async () => {

		const { teacher } = this.state;

		if (!teacher)
			return errorToast('No teacher selected');

		try {

			showLoading();

			const classId = this.props.class._id;
			const data = { class: classId };
			await request.post(`/api/admin/teachers/${teacher._id}/class`, data);
			

			await this.props.close();
			actions.updateClass(classId, { teacher });
			
			successToast("Class assigned to teacher");

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	onTeacherSelected = (teacher) => {
		return this.updateState({ teacher });
	}

	render() {

		return <Dialog open>

			<DialogTitle>
				Assign Teacher to ({ this.props.class.name })
			</DialogTitle>

			<DialogContent dividers>
				<div className='md:w-[400px]'>
					<SearchTeacherOrStudent
						mode={SearchTeacherOrStudent.MODES.TEACHER}
						onSelect={this.onTeacherSelected}
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					size='small'
					onClick={this.submit}
				>
					SAVE
				</Button>
				<Button
					size='small'
					onClick={this.props.close}
				>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>;
	}
}


export default AssignTeacher;