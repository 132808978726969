import { Avatar, Button, MenuItem, TextField } from "@mui/material";
import Page from "./Page";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { errorToast } from "../toast";
import swal from 'sweetalert';
import request from "../request";
import { hideLoading, showLoading } from "../loading";
import { GENDER } from "../../../shared/constants";
import { Link } from "react-router-dom";
import MetaTitle from "../components/MetaTitle";
import { MetaTags } from "react-meta-tags";

export default class Signup extends Page {

   state = {
      gender: ''
   }

   onGenderChange = event => {
      const gender = event.target.value;
      this.setState({ gender });
   }

   submit = async () => {

      const txtName = document.getElementById('txt-name');
      const txtSurname = document.getElementById('txt-surname');
      const txtEmail = document.getElementById('txt-email');
      const txtPhone = document.getElementById('txt-phone');
      const txtGender = document.getElementById('txt-gender');
      const txtPassword = document.getElementById('txt-password');
      const txtConfirm = document.getElementById('txt-confirm');

      const name = txtName.value;
      const surname = txtSurname.value;
      const email = txtEmail.value;
      const phone = txtPhone.value;
      const gender = this.state.gender;
      const password = txtPassword.value;
      const confirm = txtConfirm.value;

      if (!name) {
         errorToast("Enter your name");
         return txtName.focus();
      }

      if (!surname) {
         errorToast("Enter your surname");
         return txtSurname.focus();
      }

      if (!email) {
         errorToast("Enter your email");
         return txtEmail.focus();
      }

      if (!phone) {
         errorToast("Enter your phone number");
         return txtPhone.focus();
      }

      if (!gender) {
         errorToast("Select your gender");
         return txtGender.focus();
      }

      if (!password) {
         errorToast("Provide your password");
         return txtPassword.focus();
      }

      if (!confirm) {
         errorToast("Reenter your password");
         return txtConfirm.focus();
      }

      if (confirm !== password) {
         errorToast("Passwords not matching");
         txtPassword.value = '';
         txtConfirm.value = '';
         return txtPassword.focus();
      }

      try {

         showLoading();

         const data  = { name, surname, email, phone, gender, password };
         await request.post('/api/auth/profile', data);
         await swal('Account created successfully. Check your email or phone');

      } catch (err) {
         swal(err.message);
      } finally {
         hideLoading();
      }
   }

   _render() {
      return <>
         <MetaTags>
            <MetaTitle>Create Account</MetaTitle>
            <meta name="description" content="Sign up today and experience a streamlined, efficient and connected school experience" />
         </MetaTags>
         <div className="h-full flex items-center justify-center">
            <div className="w-full max-w-[400px] m-[20px]">
               <div className="flex justify-center">
                  <div>
                     <div className="flex justify-center">
                        <Avatar className="bg-[var(--primary)]">
                           <AppRegistrationIcon />
                        </Avatar>
                     </div>

                     <h1 className="flex justify-center text-xl mt-2">
                        Create acocunt
                     </h1>

                  </div>
               </div>

               <div className="[&>*]:my-[10px]">
                  <div className="grid grid-cols-2 gap-4">
                     <TextField
                        id="txt-name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                     />

                     <TextField
                        id="txt-surname"
                        label="Surname"
                        variant="outlined"
                        size="small"
                        fullWidth
                     />

                     <TextField
                        id="txt-email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                     />

                     <TextField
                        id="txt-phone"
                        label="Phone"
                        variant="outlined"
                        size="small"
                        fullWidth
                     />

                  </div>

                  <TextField
                     id="txt-gender"
                     label="Gender"
                     variant="outlined"
                     size="small"
                     fullWidth
                     value={this.state.gender}
                     select
                     onChange={this.onGenderChange}
                  >
                     {
                        Object.entries(GENDER)
                           .map(([key, value]) => {
                              return <MenuItem key={key} value={value} className="capitalize">
                                 {key}
                              </MenuItem>
                           })
                     }
                  </TextField>

                  <div className="grid grid-cols-2 gap-4">
                     <TextField
                        id="txt-password"
                        label="Password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="password"
                     />

                     <TextField
                        id="txt-confirm"
                        label="Confirm"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="password"
                     />
                  </div>

                  <Button variant="contained" fullWidth onClick={this.submit}>
                     CREATE ACCOUNT
                  </Button>

                  <div className="flex justify-center mt-4">
                     Already have an account? <Link to="/login" className="text-blue-500 ml-2">
                        Login
                     </Link>
                  </div>


               </div>
            </div>
         </div>
      </>
   }
}