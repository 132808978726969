
import { Component } from 'react';
import CenteredMessage from '../../../../components/CenteredMessage';
import PillLabel from '../../../../components/PillLabel';
import { hideLoading, showLoading } from '../../../../loading';
import swal from 'sweetalert';
import request from '../../../../request';

export default class Subjects extends Component {

	state = {
		classSubjects: null,
	}

	fetchClassSubjects = async () => {
		
		try {
			showLoading();

			const res = await request.get('/api/general/students/me');
			const classSubjects = res.data.class_subjects;

			this.setState({ classSubjects });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		this.fetchClassSubjects();
	}

	render() {

		let jsx;

		if (this.state.classSubjects === null) {
			jsx = <CenteredMessage
				message="Subjects not loaded."
				onRefresh={this.fetchClassSubjects}
			/>
		} else if (this.state.classSubjects.length === 0) {
			jsx = <CenteredMessage
				message="No subjects."
			/>
		} else {
			jsx = <>
				<PillLabel>Subjects</PillLabel>

				<div className='grid grid-cols-1 md: grid-cols-2 gap-4 mt-4'>
					{
						this.state.classSubjects.map(classSubject => {

							const { subject, teacher, average_mark } = classSubject;

							return <div key={classSubject._id} className='rounded border-solid border-[1px] border-[#ccc] p-3 cursor-pointer'>
								<div className='text-lg text-gray-600 font-semibold'>{subject.name}</div>
								<div className='text-sm text-gray-500'>
									{
										teacher ?
											<>Taught by <b>{teacher.user?.name} {teacher.user?.surname}</b></> : <>(no teacher assigned)</>
									}
								</div>
								{
									typeof average_mark === 'number' &&
										<div className={`text-sm ${average_mark >= 50 ? 'text-green-600' : 'text-red-600'}`}>
											Average mark: <b>{average_mark.toFixed(0)}</b>
										</div>
								}
							</div>
						})
					}
				</div>
			</>
		}

		return <div className='h-full'>
			{jsx}
		</div>
	}
}