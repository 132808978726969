import HomeworkTestEditor from "../HomeworkTest/HomeworkTestEditor";

export default function TestEditor(props) {
	return <HomeworkTestEditor
		close={props.close}
		entityType={HomeworkTestEditor.ENTITY_TYPES.TEST}
		mode={props.mode}
		data={props.data}
	/>
}

TestEditor.MODES = HomeworkTestEditor.MODES;