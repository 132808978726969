import { Button, Container, MenuItem, TextField } from "@mui/material";
import { Component } from "react";
import { errorToast } from "../../../toast";
import swal from 'sweetalert';
import { hideLoading, showLoading } from "../../../loading";
import request from "../../../request";
import { SCHOOL_TYPES } from "../../../../../shared/constants";


export default class AddSchool extends Component {

	state = {
		type: '',
	}

	submit = async () => {

		// Get the values from the form
		const txtName = document.getElementById('txt-name');
		const txtType = document.getElementById('txt-type');
		const txtAddress = document.getElementById('txt-address');
		const txtEmail = document.getElementById('txt-email');
		const txtPhone = document.getElementById('txt-phone');
		const txtCenterNo = document.getElementById('txt-center-no');

		const name = txtName.value;
		const type = this.state.type;
		const address = txtAddress.value;
		const email = txtEmail.value;
		const phone = txtPhone.value;
		const center_no = txtCenterNo.value;

		// Validate the values
		if (name === '') {
			errorToast('Name is required');
			return txtName.focus();
		}

		if (type === '') {
			errorToast('Type is required');
			return txtType.focus();
		}

		if (address === '') {
			errorToast('Address is required');
			return txtAddress.focus();
		}

		if (email === '') {
			errorToast('Email is required');
			return txtEmail.focus();
		}

		if (phone === '') {
			errorToast('Phone is required');
			return txtPhone.focus();
		}

		// Send the data to the server
		try {

			showLoading();

			const data = {
				name,
				type,
				address,
				email,
				phone,
				center_no: center_no || undefined,
			};

			await request.post('/api/general/school', data);

			// clear the form
			txtName.value = '';
			txtAddress.value = '';
			txtEmail.value = '';
			txtPhone.value = '';
			txtCenterNo.value = '';

			this.setState({ type: '' });

			swal('School added successfully', {
				icon: 'success',
				text: 'The school has been added successfully. To manage the school, logout and login as Admin.',
			});

		} catch(err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	render() {
		return <Container>
			<h1 className="text-xl font-bold">ADD SCHOOL</h1>

			<div className="grid grid-cols-2 gap-8 mt-8">

				<TextField
					id="txt-name"
					label="Name"
					variant="outlined"
					fullWidth
					size="large"
				/>

				<TextField
					id="txt-type"
					label="Type"
					value={this.state.type}
					onChange={e => { this.setState({ type: e.target.value }) }}
					variant="outlined"
					fullWidth
					size="large"
					select
				>
					{
						Object
							.keys(SCHOOL_TYPES)
							.map(
								key => {

									const value = SCHOOL_TYPES[key];

									return <MenuItem
										key={key}
										value={value}
									>
										{key.replaceAll('_', ' ').toUpperCase()}
									</MenuItem>
								}
							)
					}
				</TextField>

				<TextField
					id="txt-address"
					label="Address"
					variant="outlined"
					fullWidth
					size="large"
				/>

				<TextField
					id="txt-email"
					label="Email"
					variant="outlined"
					fullWidth
					size="large"
				/>

				<TextField
					id="txt-phone"
					label="Phone"
					variant="outlined"
					fullWidth
					size="large"
				/>

				<TextField
					id="txt-center-no"
					label="Center Number"
					variant="outlined"
					fullWidth
					size="large"
				/>
			</div>

			<div className="mt-8">
				<Button variant="contained" onClick={this.submit} size="large">
					SUBMIT
				</Button>
			</div>
		</Container>
	}
}