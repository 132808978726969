import HomeworkTestEditor from "./HomeworkTestEditor";

export default function HomeworkEditor(props) {
	return <HomeworkTestEditor
		close={props.close}
		entityType={HomeworkTestEditor.ENTITY_TYPES.HOMEWORK}
		mode={props.mode}
		data={props.data}
	/>
}

HomeworkEditor.MODES = HomeworkTestEditor.MODES;