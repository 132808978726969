
import React, { Component } from 'react';
import actions from '../redux/actions';
import { delay } from '../utils';

class Page extends Component {

   ref = React.createRef();

   watchHeight = () => {

      if (this.__resizeObserver)
         return;

      const watchHeight = async () => {

         if (!this.ref.current) {
            await delay(100);
            return await watchHeight();
         }

         const elem = this.ref.current;
         const isOverflowing = elem.scrollHeight > elem.clientHeight; 

         if (isOverflowing)
            elem.style.height = `${elem.scrollHeight}px`;
         else
            elem.style.height = '';
      }

      const observer = new ResizeObserver(watchHeight);

      observer.observe(this.ref.current);
      this.__resizeObserver = observer;

      watchHeight();

   }

   stopWatchingHeight = () => {
      if (this.__resizeObserver)
         this.__resizeObserver.disconnect();
   }

   componentWillUnmount() {
      this.stopWatchingHeight();
   }

   componentDidMount() {

      const route = window.location.pathname;
      actions.setCurrentRoute(route);
      window.scrollTo(0, 0);

      this.watchHeight()
      
   }

   _render() {
      return <div>Please implement <code>_render()</code></div>
   }

   render() {
      return <main>
         <div className='page' ref={this.ref}>
            {this._render()}
         </div>
      </main>
   }

}

export default Page;