
import React, { Component } from "react";
import request from '../../../request';

const TeacherContext = React.createContext();

class TeacherContextProvider extends Component {

	state = {
		classSubjects: null,
	}

	fetchClassSubjects = async () => {
		const res = await request.get('/api/teacher/class-subjects');
		const classSubjects = res.data;
		await this.updateState({ classSubjects });
	}

	render() {
		return <TeacherContext.Provider
			value={{
				classSubjects: this.state.classSubjects,
				fetchClassSubjects: this.fetchClassSubjects,
			}}
		>
			{this.props.children}
		</TeacherContext.Provider>
	}
}


export default TeacherContext;
export { TeacherContextProvider };