import { Component } from "react";
import { connect } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import Editor from "./Editor";
import { hideLoading, showLoading } from "../../../../loading";
import request from "../../../../request";
import swal from "sweetalert";
import Grading from "./Grading";
import CenteredMessage from "../../../../components/CenteredMessage";
import { Fab, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";


class GradingsUnconnected extends Component {

	state = {
		gradings: null,
		editorOpen: false,
	}

	openEditor = () => {
		this.setState({ editorOpen: true });
	}

	closeEditor = (grading) => {
		const update = { editorOpen: false };

		if (grading)
			update.gradings = [ ...this.state.gradings, grading ];

		this.setState(update);
	}

	fetchGradings = async () => {
		try {

			showLoading('Fetching subjects...');

			const res = await request.get('/api/teacher/gradings');
			const { gradings } = res.data;
			this.setState({ gradings })

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	delete = _id => {
		const gradings = this.state.gradings.filter(item => item._id !== _id);
		return this.setState({ gradings });
	}

	edit = grading => {
		const { _id } = grading;

		const gradings = this.state.gradings.map(item => {
			if (item._id === _id)
				item = grading;
			return item;
		})

		return this.setState({ gradings });
	}

	componentDidMount() {
		this.fetchGradings();
	}

	render() {

		let jsx;
		const { gradings } = this.state;

		if (!gradings) {
			jsx = <CenteredMessage 
				message={"Gradings not fetched."}
				onRefresh={this.fetchGradings}
			/>
		} else if (gradings.length === 0) {
			jsx = <EmptyState
				title="No Items"
				subtitle="You haven't added any grading this term"
				addButtonCaption="Create Grading"
				onAddButtonClicked={this.openEditor}
			/>
		} else {
			jsx = gradings.map(grading => {
				return <Grading
					key={grading._id}
					data={grading}
					school={this.props.school}
					onDelete={this.delete}
					onEdit={this.edit}
				/>
			})
		}

		return <div className="h-full grid grid-rows-[1fr,auto]">
			<div className="overflow-hidden">
				{jsx}
				{
					this.state.editorOpen && <Editor
						close={this.closeEditor}
					/>
				}
			</div>
			<div className="text-right p-2">
				<IconButton onClick={this.fetchGradings}>
					<RefreshIcon />
				</IconButton>
				<Fab
					color="primary"
					size="small"
					onClick={this.openEditor}
				>
					<AddIcon />
				</Fab>
			</div>
		</div>
	}
}

const mapStateToProps = state => ({ school: state.user?.school })
const Gradings = connect(mapStateToProps)(GradingsUnconnected);

export default Gradings;