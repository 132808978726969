import { useParams, useSearchParams } from "react-router-dom";

const withRouterParams = (Component) => {
	return function (props) {

		const params = useParams();
		const [ urlSearchParams, ]= useSearchParams();

		const searchParams = {};

		for (const [key, value] of urlSearchParams.entries()) {
			searchParams[key] = value;
		}

		return <Component
			{...props}
			params={params}
			searchParams={searchParams}
		/>
	}
}

export default withRouterParams;