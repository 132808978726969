


import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf();

function errorToast(text) {
	notyf.error(text);
}

function successToast(text) {
	notyf.success(text);
}


export {
	errorToast,
	successToast,
}