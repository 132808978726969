import { Component } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddSchoolIcon from '@mui/icons-material/AddBusiness';
import AddSchool from "./AddSchool";
import Dashboard from "./Dashboard";

const SIDEBAR_VALUES = {
   DASHBOARD: 'dashboard',
   ADD_SCHOOL: 'add-school',
};

export default class Portal extends Component {

   static title = "Portal";
   static sidebarItems = [
      {
			caption: "Dashboard",
			icon: DashboardIcon,
			value: SIDEBAR_VALUES.DASHBOARD
		},
      {
         caption: "Add School",
         icon: AddSchoolIcon,
         value: SIDEBAR_VALUES.ADD_SCHOOL
      }
   ];

   render() {

      let jsx;

      switch (this.props.sidebarValue) {
         case SIDEBAR_VALUES.ADD_SCHOOL:
            jsx = <AddSchool />
            break;
         default:
            jsx = <Dashboard />
      }

      return <div className="h-full p-4">
         {jsx}
      </div>
   }
}