
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAqzYg0rnNy-1zbYw-kveF7F5x22nA38YE",
	authDomain: "classmate-e5d1f.firebaseapp.com",
	projectId: "classmate-e5d1f",
	storageBucket: "classmate-e5d1f.appspot.com",
	messagingSenderId: "901895204666",
	appId: "1:901895204666:web:b1c4f9f9b328b936a8aa03",
	measurementId: "G-PH44H9EJ5D"
};

const firebase = {
	init() {
		// Initialize Firebase
		const app = initializeApp(firebaseConfig);
		this._app = app;

		this.initializeMessaging();
	},

	async getToken() {
		const vapidKey = 'BPl_DfnibLFI3o4YxJK8rEVw5ffUDE_tOEhBpe2aD2xI6zyU0GAejvMH6MLBggtJKJBSFPhIH7PHpr21lR-UjYM'
		const token = await getToken(this._messaging, { vapidKey });
		return token;
	},

	async initializeMessaging() {
		const messaging = getMessaging(this._app);
		this._messaging = messaging;

		const token = await this.getToken();
		console.log(token);

		onMessage((payload) => {
			console.log('Message received. ', payload);
			// ...
		});
	}

}


export default firebase;