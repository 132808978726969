import { Component } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import swal from 'sweetalert';
import { showLoading, hideLoading } from '../../../loading';
import request from '../../../request'
import { Button } from "@mui/material";
import Dashboard from "./Dashboard";
import Students from "./Students";
import Teachers from "./Teachers";
import SchoolContext from "./SchoolContext";
import { SiGoogleclassroom } from "react-icons/si";
import { GiTeacher } from "react-icons/gi";
import { PiStudentBold } from "react-icons/pi";
import { GrSchedules } from "react-icons/gr";
import Classes from "./Classes";
import Timetable from "../Timetable";


const SIDEBAR_VALUES = {
   DASHBOARD: 'dashboard',
   CLASSES: 'classes',
   TEACHERS: 'teachers',
   STUDENTS: 'students',
   TIMETABLE: 'timetable'
}

export default class AdminPortal extends Component {
   
      static title = "Admin Portal";

      static sidebarItems = [
         {
            caption: "Dashboard",
            icon: DashboardIcon,
            value: SIDEBAR_VALUES.DASHBOARD
         },
         {
            caption: "Classes",
            icon: SiGoogleclassroom,
            value: SIDEBAR_VALUES.CLASSES,
         },
         {
            caption: "Teachers",
            icon: GiTeacher,
            value: SIDEBAR_VALUES.TEACHERS,
         },
         {
            caption: "Students",
            icon: PiStudentBold,
            value: SIDEBAR_VALUES.STUDENTS,
         },
         {
            caption: "Timetable",
            icon: GrSchedules,
            value: SIDEBAR_VALUES.TIMETABLE,
         }
      ];

      state = {
         school: null
      }

      fetchSchoolProfile = async () => {

         try {

            showLoading();

            const res = await request.get('/api/admin/school');
            const school = res.data;

            this.updateState({ school });

         } catch (err) {
            swal(err.toString());
         } finally {
            hideLoading();
         }
      }

      updateSchool = (update={}) => {
         const school = { ...(this.state.school || {}), ...update };
         return this.updateState({ school });
      }

      componentDidMount() {
         this.fetchSchoolProfile();
      }
   
      render() {
         
         if (!this.state.school) {
            return <div className="h-full flex justify-center items-center">
               <div>
                  <p className="text-gray-600 text-sm">
                     Failed to load data.
                  </p>

                  <Button onClick={this.fetchSchoolProfile} fullWidth>
                     RETRY
                  </Button>
               </div>
            </div>
         }

         let display;

         switch (this.props.sidebarValue) {
            case SIDEBAR_VALUES.DASHBOARD:
               display = <Dashboard />
               break;
            case SIDEBAR_VALUES.TEACHERS:
               display = <Teachers />
               break;
            case SIDEBAR_VALUES.STUDENTS:
               display = <Students />
               break;
            case SIDEBAR_VALUES.CLASSES:
               display = <Classes />
               break;
            case SIDEBAR_VALUES.TIMETABLE:
               display = <Timetable allowEditing />
               break;
            default:
               break;
         }

         return <SchoolContext.Provider
            value={{
               school: this.state.school, 
               updateSchool: this.updateSchool
            }}
         >
            {display}
         </SchoolContext.Provider>
      }
}