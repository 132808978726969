import { Component } from "react";
import PillLabel from "../../../../components/PillLabel";
import { Avatar, Button, CircularProgress, Divider, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import request from '../../../../request';
import swal from 'sweetalert';
import ImportedCenteredMessage from '../../../../components/CenteredMessage';
import RefreshIcon from "@mui/icons-material/Refresh";
import AddParent from "./AddParent";
import { requestConfirmation } from '../../../../utils';


export class Parents extends Component {

	state = {
		parents: null,
		showLoading: false,
		showAddParentModal: false
	}

	openAddParentModal = () => {
		this.setState({ showAddParentModal: true });
	}

	closeAddParentModal = (parent) => {
		const update = { showAddParentModal: false }

		if (parent) {
			const parents = [ ...this.state.parents, parent ];
			update.parents = parents;
		}

		this.setState(update);
	}

	fetchParents = async () => {

		try {

			this.setState({ showLoading: true });

			const res = await request.get('/api/student/parents');
			const { parents } = res.data;

			this.setState({ parents });

		} catch (err) {
			swal(String(err));
		} finally {
			this.setState({ showLoading: false })
		}

	}

	removeParent = async (parent) => {
		
		try {

			const question = `Are you sure that you want to remove "${parent.name} ${parent.surname}" as your parent? They will be notified of this action.`;
			const confirm = await requestConfirmation(question);

			if (!confirm) return;

			await request.delete(`/api/student/parents/${parent._id}`);

			const updatedParents = this.state.parents.filter(p => p._id !== parent._id);
			this.setState({ parents: updatedParents });

			swal('Parent removed successfully');

		} catch (err) {
			swal(String(err));
		}

	}

	componentDidMount() {
		this.fetchParents();
	}

	render() {

		const { parents, showLoading } = this.state;
		let parentsJSX;

		if (showLoading) {
			parentsJSX = <div className="flex justify-center py-[75px]">
				<CircularProgress size={30} thickness={4} />
			</div>
		} else if (!parents) {
			parentsJSX = <CenteredMessage
				message={"Parents failed to load"}
			/>
		} else if (parents.length === 0) {
			parentsJSX = <CenteredMessage
				message={"You haven't added any parent/guardian to your account"}
			/>
		} else {
			parentsJSX = this.state.parents.map(parent => {
				return <div key={parent._id}>
					<div className="grid grid-cols-[auto,1fr,auto] gap-2 mt-4">
						<span>
							<Avatar>
								<PersonIcon />
							</Avatar>
						</span>
						<div>
							<div className="text-sm text-gray-600 font-bold truncate">
								{parent.name} {parent.surname}
							</div>
							<div className="text-sm text-gray-600 truncate">
								{parent.email}
							</div>
						</div>
						<span>
							<IconButton color="error" onClick={() => this.removeParent(parent)}>
								<DeleteIcon />
							</IconButton>
						</span>
					</div>

					<Divider className="mt-4" />
				</div>
			});
		}

		return <div className="shadow-md p-4">

			<PillLabel>PARENTS</PillLabel>

			{parentsJSX}

			<div className="text-right mt-4">
				<IconButton onClick={this.fetchParents} size="small" className="mr-4">
					<RefreshIcon />
				</IconButton>
				<Button size="small" startIcon={<AddIcon />} onClick={this.openAddParentModal}>
					ADD PARENT
				</Button>
				{
					this.state.showAddParentModal && <AddParent
						close={this.closeAddParentModal}
					/>
				}
			</div>

		</div>
	}
}

function CenteredMessage({ message }) {
	return <div className="h-[100px]">
		<ImportedCenteredMessage message={message} />
	</div>
}