import { Component } from "react";
import TabPanel from "./TabPanel";
import TabPanelPagination from "./TabPanelPagination";
import { Chip, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LessonPlanEditor from "./LessonPlanEditor";


export default class LessonPlans extends Component {

	state = {
		editorMode: null,
		editorData: null,
	}

	render() {

		let body;
		const { lessonPlans } = this.props;

		if (!lessonPlans) {
			body = <div>Loading...</div>
		} else if (lessonPlans.length === 0) {
			body = <div>No lesson plans found</div>
		} else {
			body = <div>
				{lessonPlans.map(lessonPlan => {
					return <Chip
						key={lessonPlan._id}
						label={`Week ${lessonPlan.week} — Day ${lessonPlan.day}`}
						color="secondary"
						className="m-1 p-1 cursor-pointer"
						onClick={() => this.setState({ editorMode: LessonPlanEditor.MODES.VIEW, editorData: lessonPlan })}
					/>
				})}
			</div>
		}

		const footer = <div className="grid grid-cols-[1fr,auto]">
			<TabPanelPagination
				page={this.props.lessonPlansPage}
				pageSize={this.props.pageSize}
				itemCount={this.props.count}
			/>
			<Fab
				size="small"
				color="primary"
				onClick={() => this.setState({ editorMode: LessonPlanEditor.MODES.ADD })}
			>
				<AddIcon />
			</Fab>
		</div>

		return <>
			<TabPanel
				heading="Lesson Plans"
				onRefresh={this.props.onRefresh}
				body={body}
				loaded={!!this.props.lessonPlans}
				footer={footer}
			/>
			{
				this.state.editorMode && <LessonPlanEditor
					close={() => {
						this.setState({ editorMode: null });

						if (this.state.editorMode === LessonPlanEditor.MODES.ADD) {
							this.props.onRefresh();
						}
					}}
					mode={this.state.editorMode}
					data={this.state.editorData}
					classSubjectId={this.props.classSubjectId}
					onDelete={this.props.onDelete}
				/>
			}
		</>
	}
}