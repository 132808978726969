import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Component } from "react";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import request from "../../../../request";
import AddTeacherOrStudent from "../AddTeacherOrStudent";
import { requestConfirmation } from "../../../../utils";
import { successToast } from "../../../../toast";
import FloatingActionButtons from "../../FloatingActionButtons";
import { MODES as AddTeacherOrStudentModes } from "../AddTeacherOrStudent";

export default class Teachers extends Component {

   state = {
      teachers: null,
      teacherEditorOpen: false,
   }

   openTeacherEditor = () => {
      this.setState({ teacherEditorOpen: true });
   }

   closeTeacherEditor = () => {
      this.setState({ teacherEditorOpen: false });
   }

   deleteTeacher = async (teacher) => {

      const confirm = await requestConfirmation('Are you sure you want to delete this teacher?');

      if (!confirm)
         return;

      try {
            
         showLoading();

         await request.delete(`/api/admin/teachers/${teacher._id}`);

         successToast("Teacher deleted successfully");

         const teachers = this.state.teachers.filter(t => t._id !== teacher._id);
         this.updateState({ teachers });

      } catch (err) {
         swal(err.toString());
      } finally {
         hideLoading();
      }

   }



   fetchTeachers = async () => {
      try {

         showLoading();

         const res = await request.get('/api/admin/teachers');
         const teachers = res.data;

         this.setState({ teachers });

      } catch (err) {
         swal(err.toString());
      } finally {
         hideLoading();
      }
   }

   addTeacher = (teacher) => {
      const teachers = [ ...this.state.teachers, teacher ];
      return this.updateState({ teachers });
   }

   componentDidMount() {
      this.fetchTeachers();
   }

   render() {

      let teachers;

      if (!this.state.teachers) {
         teachers = <TableRow>
            <TableCell colSpan={100}>
               <div className="flex justify-center items-center h-400">
                  <div>
                     <p className="text-sm text-gray-600">Teachers not loaded</p>
                     <Button
                        onClick={this.fetchTeachers}
                     >
                        Retry
                     </Button>
                  </div>
               </div>
            </TableCell>
         </TableRow>
      } else if (!this.state.teachers.length) {
         teachers = <TableRow>
            <TableCell colSpan={100}>
               <div className="flex justify-center items-center h-400">
                  <p className="text-sm text-gray-600">No teachers found</p>
               </div>
            </TableCell>
         </TableRow>
      } else {

         teachers = this.state.teachers.map(teacher => {
            return <TableRow key={teacher._id}>
               <TableCell>{teacher.teacher_no}</TableCell>
               <TableCell>{teacher.user?.name}</TableCell>
               <TableCell>{teacher.user?.surname}</TableCell>
               <TableCell>{teacher.user?.phone}</TableCell>
               <TableCell>{teacher.user?.email}</TableCell>
               <TableCell>
                  {teacher.class?.name}
               </TableCell>
               <TableCell>
                  <Button
                     color="primary"
                  >
                     Edit
                  </Button>
                  <Button
                     color="error"
                     onClick={() => this.deleteTeacher(teacher)}
                  >
                     Delete
                  </Button>
               </TableCell>
            </TableRow>
         });
      }

      return  <div className="relative h-full">

         <TableContainer className="h-full" component={Paper}>
            <Table size="small">
               <TableHead>
                  <TableRow>
                     <TableCell>Teacher No</TableCell>
                     <TableCell>Name</TableCell>
                     <TableCell>Surname</TableCell>
                     <TableCell>Phone</TableCell>
                     <TableCell>Email</TableCell>
                     <TableCell>Class</TableCell>
                     <TableCell>Actions</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {teachers}
               </TableBody>
            </Table>
         </TableContainer>

         <FloatingActionButtons
            onAdd={this.openTeacherEditor}
            onRefresh={this.fetchTeachers}
         />

         {
            this.state.teacherEditorOpen && <AddTeacherOrStudent
               close={this.closeTeacherEditor}
               mode={AddTeacherOrStudentModes.TEACHER}
               addToList={this.addTeacher}
            />
         }

      </div>
   }
}