

const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
const SET_ROLE = 'SET_ROLE';
const SET_SUBJECTS = 'SET_SUBJECTS';
const SET_USER  = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';
const SET_CLASSES = 'SET_CLASSES';
const ADD_CLASS = 'ADD_CLASS';
const UPDATE_CLASS = 'UPDATE_CLASS';
const DELETE_CLASS = 'DELETE_CLASS';
const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
const SET_IS_PWA = 'SET_IS_PWA';

export {
   ADD_CLASS,
   DELETE_CLASS,
   SET_AUTHENTICATED,
   SET_CLASSES,
   SET_CURRENT_ROUTE,
   SET_IS_PWA,
   SET_ROLE,
   SET_SUBJECTS,
   SET_USER,
   UPDATE_CLASS,
   UPDATE_USER,
}