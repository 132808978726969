
export default function NumberCard(props) {

   const {
      text,
      number,
      bgClassname="bg-gradient-to-tr from-[var(--primary)] to-blue-300"
   } = props;
   
   return <div className={`rounded-xl py-4 px-6 ${bgClassname}`}>
      <div className="text-3xl text-white font-bold">
         {number}
      </div>
      <div className="text-lg text-gray-300">
         {text}
      </div>
   </div>
}