import HomeworkTestList from "./HomeworkTestList";
import TabPanelPagination from "./TabPanelPagination";
import PMessage from "./PMessage";
import TabPanel from "./TabPanel";
import HomeworkTestThumbnail from '../../../HomeworkTest/Thumbnail';
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Component } from "react";
import TestEditor from "../../TestEditor";

export default class Tests extends Component {

	state = {
		testEditorOpen: false,
	}

	addTest = () => {
		this.setState({ testEditorOpen: true });
	}

	render() {

		const { tests, loading, error, onRefresh, onDismissError, page, count, pageSize, onPageChange } = this.props;
		let testsJSX;

		if (tests) {
			if (tests.length > 0) {
				testsJSX = <HomeworkTestList>
					{
						tests.map(test => {
							return <HomeworkTestThumbnail {...test} onDelete={onRefresh} />
						})
					}
				</HomeworkTestList>
			} else {
				testsJSX = <p className="text-gray-600 text-lg">
					No tests recorded
				</p>
			}
		} else {
			testsJSX = <PMessage>
				Tests not loaded
			</PMessage>
		}

		const footer = <div className="grid grid-cols-[1fr,auto]">
			<TabPanelPagination
				itemCount={count}
				pageSize={pageSize}
				page={page}
				onChange={onPageChange}
				fetch={onRefresh}
			/>

			<IconButton size="small" onClick={this.addTest}>
				<AddIcon />
			</IconButton>

			{
				this.state.testEditorOpen && <TestEditor
					close={() => { 
						this.setState({ testEditorOpen: false });
						this.props.onAdd();
					}}
				/>
			}
		</div>

		return <TabPanel
			heading="Tests"
			body={testsJSX}
			loading={loading}
			error={error}
			onRefresh={onRefresh}
			onDismissError={onDismissError}
			footer={footer}
			loaded={!!tests}
		/>
	}

}