import { Component } from "react"
import { hideLoading, showLoading } from "../../../loading"
import request from "../../../request";
import swal from "sweetalert";
import CenteredMessage from "../../../components/CenteredMessage";
import Thumbnail from "../HomeworkTest/Thumbnail";
import { Divider } from "@mui/material";

export default class Homework extends Component {

	state = {
		homework: null,
	}

	fetchHomework = async () => {

		try {

			showLoading();

			const params = {}
			if (this.props.studentId)
				params.student = this.props.studentId;

			const res = await request.get('/api/parent/homework', { params });
			const { homework } = res.data;
			this.setState({ homework });

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading()
		}

	}

	renderHomeworkList = homework => {
		return <div className="md:grid grid-cols-2 lg:grid-cols-3 gap-4">
			{
				homework.map(homework => {
					return <Thumbnail
						key={homework._id}
						{...homework}
						isHomework
					/>
				})
			
			}
		</div>
	}

	componentDidMount() {
		this.fetchHomework();
	}

	render() {

		const { homework } = this.state;

		if (homework) {

			if (homework.length === 0) {
				return <CenteredMessage
					message="No homework"
				/>
			}

			if (this.props.studentId) // no need to group by child
				return this.renderHomeworkList(homework);

			// group by child
			const homeworkByChildMap = homework
				.reduce((acc, homework) => {
					const childId = homework.student._id;
					let childHomework = acc[childId];

					if (!childHomework) {
						childHomework = [];
						acc[childId] = childHomework;
					}

					childHomework.push(homework);
					return acc;

				}, {});

			const jsx = Object
				.entries(homeworkByChildMap)
				.map(([ childId, childHomework ]) => {

					return <div key={childId}>
						<div className="text-lg text-gray-600 font-bold my-2">
							For {childHomework[0].student.user.name} {childHomework[0].student.user.surname}
						</div>
						{this.renderHomeworkList(childHomework)}
						<Divider className="my-4" />

					</div>
				});

				return <div className="p-4">
					{jsx}
				</div>

		} else {
			return <CenteredMessage
				message={"Homework not loaded."}
				onRefresh={this.fetchHomework}
			/>
		}

	}
}