import Refresh from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";



export default function CenteredMessage({ message, onRefresh }) {
	return <div className="flex justify-center items-center h-full">
		<div className="text-center">
			<p className="text-gray-600 text-lg">{message}</p>
			{
				onRefresh && <IconButton onClick={onRefresh} color="primary">
					<Refresh />
				</IconButton>
			}
		</div>
	</div>
}