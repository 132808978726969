import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Component } from "react";
import { errorToast, successToast } from '../../../../toast';
import { hideLoading, showLoading } from '../../../../loading';
import request from "../../../../request";
import swal from "sweetalert";

export default class AddParent extends Component {

	submit = async () => {

		const txtEmail = document.getElementById('txt-email');
		const email = txtEmail.value;

		if (!email) {
			errorToast('Provide your parent\'s email');
			return txtEmail.focus();
		}

		try {

			showLoading();

			const data = { email, is_new: false };
			const res = await request.post('/api/student/parents', data);

			successToast('Parent added sucessfully');
			this.props.close(res.data);


		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	render() {
		return <Dialog open>
			<DialogTitle>Add Parent</DialogTitle>
			<DialogContent dividers>
				<TextField
					id="txt-email"
					label="Email"
					variant="outlined"
					size="small"
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button size="small" variant="contained" onClick={this.submit}>
					ADD
				</Button>
				<Button size="small" onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}