import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../loading";
import request from "../../../request";
import Quill from "quill";
import Refresh from "@mui/icons-material/Refresh";
import HomeworkEditor from "./HomeworkEditor";
import TestEditor from "../TeacherPortal/TestEditor";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { successToast } from "../../../toast";
import { requestConfirmation } from "../../../utils";
import Marks from "./Marks";


export default class Display extends Component {

	state = {
		data: null,
		showingEditor: false,
		marksEditorOpen: false,
	}

	quillRef = React.createRef();

	showEditor = () => {
		this.setState({ showingEditor: true });
	}

	isHomework = () => {
		return this.props.isHomework;
	}

	hideEditor = (data) => {
		const update = { showingEditor: false };
		if (data)
			update.data = { ...this.state.data, ...data };

		this.setState(update);
	}

	loadContent = async () => {
		try {

			showLoading();

			const testsOrHomework = this.isHomework() ? 'homework' : 'tests';
			const res = await request.get(`/api/general/${testsOrHomework}/${this.props._id}`);
			const data = res.data;
			await this.updateState({ data });
			
			this.renderQuill();

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	renderQuill() {
		if (this.quill)
			return;

		this.quill = new Quill(this.quillRef.current, {
			theme: 'snow',
			readOnly: true,
		});

		this.setQuillContents(this.state.data.content);

	}

	setQuillContents(json = '') {
		const contents = JSON.parse(json);
		this.quill.setContents(contents);
	}

	delete = async () => {

		const confirm = await requestConfirmation('Are you sure you want to delete this?');

		if (!confirm)
			return;

		try {
			showLoading();
			const testsOrHomework = this.isHomework() ? 'homework' : 'tests';
			await request.delete(`/api/teacher/${testsOrHomework}/${this.props._id}`);
			successToast('Deleted');
			this.props.close();

			if (typeof this.props.onDelete === 'function')
				this.props.onDelete(this.props._id);

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	getTestName = () => {
		return this.state.data?.name || this.props.name
	}


	componentDidUpdate(prevProps, prevState) {

		const prevContent = prevState.data?.content;
		const content = this.state.data?.content;

		if (prevContent && content) {
			if (prevContent !== content) {
				try { this.setQuillContents(content) } catch(err) { console.log(err)}
			}
		}
	}

	componentDidMount() {
		this.loadContent();
	}

	render() {

		let dialogContent, editBtn, deleteBtn;

		if (this.state.data === null) {
			dialogContent = <div className="flex justify-center items-center h-[200px] aspect-square">
				<div className="text-center text-gray-600">
					<div className="text-lg">Data not loaded</div>
					<IconButton onClick={this.loadContent} color="primary">
						<Refresh />
					</IconButton>
				</div>
			</div>;
		} else {
			dialogContent = <div>
				<div ref={this.quillRef} />
			</div>
			
			editBtn = <IconButton variant="contained" onClick={this.showEditor} size="small" disabled={!this.props.allowEditing}>
				<EditIcon />
			</IconButton>

			deleteBtn = <IconButton variant="contained" onClick={this.delete} size="small" color="error" disabled={!this.props.allowEditing}>
				<DeleteIcon />
			</IconButton>
		}

		let marksBtn;

		if (!this.isHomework() && this.props.allowEditing) {
			marksBtn = <>
				<Button
					size='small'
					className="mr-4"
					onClick={() => this.setState({ marksEditorOpen: true })}
				>
					MARKS
				</Button>

				{
					this.state.marksEditorOpen && <Marks
						close={() => this.setState({ marksEditorOpen: false })}
						testId={this.props._id}
						testName={this.getTestName()}
					/>
				}
			</>
		}

		const Editor = this.isHomework() ? HomeworkEditor : TestEditor;

		return <Dialog open fullScreen>
			<DialogTitle>
				{this.getTestName()}
			</DialogTitle>
			<DialogContent dividers>
				{dialogContent}
			</DialogContent>
			<DialogActions>
				{marksBtn}
				{editBtn}
				{deleteBtn}
				<Button variant='text' onClick={this.props.close} size='small'>
					CLOSE
				</Button>
			</DialogActions>
			{
				this.state.showingEditor && <Editor
					close={this.hideEditor}
					mode={Editor.MODES.EDIT}
					data={this.state.data}
				/>
			}
		</Dialog>
	}
}