import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Component } from "react";
import { showLoading, hideLoading } from "../../../loading";
import request from "../../../request";
import CenteredMessage from "../../../components/CenteredMessage";
import swal from "sweetalert";
import ContentEditable from "../../../components/ContentEditable";
import { errorToast, successToast } from '../../../toast';
import Refresh from "@mui/icons-material/Refresh";


export default class Marks extends Component {

	state = {
		marks: null,
		savedMarks: null,
		changed: false,
	}

	fetchMarks = async () => {
		
		try {

			showLoading();

			const testId = this.getTestId();
			const res = await request.get(`/api/teacher/tests/${testId}/marks`);

			const { marks } = res.data;
			this.setState({ marks, savedMarks: marks, changed: false });
		
		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	updateMark = (studentId, update={}) => {
		const { marks } = this.state;
		const updatedMarks = [];

		for (const mark of marks) {
			
			if (mark.student._id === studentId) {
				// check if there are changes
				let changed = false;
				for (const key in update) {
					if (update[key] !== mark[key]) {
						changed = true;
						break;
					}
				}

				if (!changed)
					return;

				// merge changes
				updatedMarks.push({ ...mark, ...update });

			} else {
				updatedMarks.push(mark);
			}
		}

		this.setState({ marks: updatedMarks, changed: true });
	}

	getTestId() {
		return this.props.testId;
	}

	save  = async () => {

		const changed = this.state.marks.filter((mark, i) => {
			const savedMark = this.state.savedMarks[i];
			return mark.mark !== savedMark.mark || mark.remark !== savedMark.remark;
		});

		if (changed.length === 0) {
			errorToast("No changes to save");
			return;
		}

		const marks = changed.map(item => {
			const student = item.student._id;
			const { mark, remark } = item;
			return { student, mark, remark };
		});

		const data = { marks }

		try {
			showLoading();

			const testId = this.getTestId();
			const url = `/api/teacher/tests/${testId}/marks`;
			
			await request.post(url, data);
			successToast('Saved');

			this.setState({ changed: false, savedMarks: this.state.marks })

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		this.fetchMarks();
	}

	render() {

		const { marks } = this.state;

		let dialogContent;

		if (!marks) {
			dialogContent = <CenteredMessage
				message="Info not loaded yet"
				onRefresh={this.fetchMarks}
			/>
		} else if (marks.length === 0) {
			dialogContent = <CenteredMessage
				message="No students have taken this test yet."
				onRefresh={this.fetchMarks}
			/>
		} else {
			dialogContent = <Table>
				<TableHead>
					<TableRow>
						<TableCell>NAME</TableCell>
						<TableCell>SURNAME</TableCell>
						<TableCell>MARK</TableCell>
						<TableCell>REMARK</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						marks.map(({ student, mark, remark }) => {
							return <TableRow key={student._id}>
								<TableCell>{student.user?.name}</TableCell>
								<TableCell>{student.user?.surname}</TableCell>
								<TableCell>
									<ContentEditable
										value={mark}
										type="number"
										onBlur={value => this.updateMark(student._id, { mark: value })}
									/>
								</TableCell>
								<TableCell>
									<ContentEditable
										value={remark}
										onBlur={remark => {this.updateMark(student._id, { remark })}}
									/>
								</TableCell>
							</TableRow>
						})
					}
				</TableBody>
			</Table>
		} 
		

		return <Dialog open fullScreen>
		
			<DialogTitle>Marks</DialogTitle>

			<DialogContent dividers>
				{dialogContent}
			</DialogContent>

			<DialogActions>
				<IconButton onClick={this.fetchMarks}>
					<Refresh />
				</IconButton>
				<Button onClick={this.save} size="small" variant="contained" disabled={!this.state.changed}>
					SAVE
				</Button>
				<Button onClick={this.props.close} size="small">
					CLOSE
				</Button>
			</DialogActions>

		</Dialog>
	}
}