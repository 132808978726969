

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material';
import TeacherContext from '../TeacherContext';
import swal from 'sweetalert';
import { showLoading, hideLoading } from '../../../../loading';
import { connect } from 'react-redux';
import { SCHOOL_TYPES } from '../../../../../../shared/constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import AttendanceList from './AttendanceList';
import request from '../../../../request';
import CenteredMessage from '../../../../components/CenteredMessage';
import EmptyState from '../../../../components/EmptyState';


class RecordAttendanceUnconnected extends React.Component {

	static contextType = TeacherContext;

	state = {
		students: null,
		classSubject: null,
		attendanceList: [],
	}

	fetchClassSubjects = async () => {
		try {
			showLoading();
			await this.context.fetchClassSubjects();
		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	fetchStudents = async () => {
		try {

			showLoading();
			
			let students;

			if (this.getSchoolType() === SCHOOL_TYPES.HIGH_SCHOOL) {
				const classSubjectId = this.state.classSubject;
				const res = await request.get(`/api/teacher/class-subjects/${classSubjectId}`);
				students = res.data.students;
			} else {
				const classId = this.props.user.class._id;
				const res = await request.get('/api/general/students', { params: { class: classId }});
				students = res.data.students;
			}

			await this.updateState({ students, attendanceList: [] });

		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	submit = async () => {
		try {

			showLoading();
			
			const data = {
				students: this.state.attendanceList.map(item => item._id),
			};

			if (this.getSchoolType() === SCHOOL_TYPES.HIGH_SCHOOL)
				data.class_subject = this.state.classSubject;

			await request.post('/api/teacher/attendances', data)
			await this.props.close();

		} catch (err){
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	getSchoolType() {
		return this.props.user.school.type;
	}

	componentDidMount() {
		if (this.getSchoolType() === SCHOOL_TYPES.HIGH_SCHOOL) {
			if (!this.context.classSubjects)
				this.fetchClassSubjects();
		} else {
			this.fetchStudents();
		}
	}

	render() {

		const schoolType = this.getSchoolType();

		let studentList;
		const { students } = this.state;

		if (!students) {
			studentList = <CenteredMessage
				message={"Students not fetched."}
				onRefresh={this.fetchStudents}
			/>
		} else if (students.length === 0) {
			studentList = <EmptyState
				title='No students'
				subtitle='The student list is empty'
			/>
		} else {
			studentList = <AttendanceList
				students={students}
				refresh={this.fetchStudents}
				attendanceList={this.state.attendanceList}
				onAttendanceListChange={
					(student, present) => {

						let attendanceList;

						if (present)
							attendanceList = [ ...this.state.attendanceList, student ];
						else
							attendanceList = this.state.attendanceList.filter(item => item._id !== student._id);

						return this.updateState({ attendanceList });
					}
				}
			/>
		}

		let dialogContent;

		if (schoolType === SCHOOL_TYPES.HIGH_SCHOOL) {
			
			const { classSubjects } = this.context;
			
			if (classSubjects) {

				if (!this.state.classSubject) {
					studentList = <CenteredMessage
						message={"Students will appear after you select the subject"}
					/>
				}

				dialogContent = <div className='h-full grid grid-rows-[auto,1fr] gap-4 pt-4'>
					<div>
						<TextField
							label="Select subject"
							variant='outlined'
							size='small'
							onChange={
								async e => {
									await this.updateState({
										classSubject: e.target.value,
										attendanceList: [],
										students: null
									});
									this.fetchStudents();
								}
							}
							select
							fullWidth
						>
							{
								classSubjects.map(item => {
									return <MenuItem value={item._id}>
										{item.subject.name} - Form {item.class.level} ({item.class.name})
									</MenuItem>
								})
							}
						</TextField>
					</div>

					<div className='overflow-y-auto'>{studentList}</div>

				</div>
			} else {
				dialogContent = <div className='text-lg text-gray-600'>
					Subjects not fetched <IconButton color='primary' onClick={this.fetchClassSubjects}>
						<RefreshIcon className="text-lg" />
					</IconButton>
				</div>
			}
		} else {
			dialogContent = studentList;
		}

		return <Dialog open fullScreen>
			<DialogTitle>Record attendance</DialogTitle>

			<DialogContent dividers>
				<div className='h-full overflow-y-auto'>
					{dialogContent}
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					size='small'
					onClick={this.submit}
				>
					SUBMIT
				</Button>
				<Button
					variant='text'
					size='small'
					onClick={this.props.close}
				>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}

const mapStateToProps = state => ({ user: state.user });
const RecordAttendance = connect(mapStateToProps)(RecordAttendanceUnconnected);

RecordAttendance.propTypes = {
	close: PropTypes.func.isRequired
};

export default RecordAttendance;