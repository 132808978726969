

import React from 'react';
import PropTypes from 'prop-types';
import SchoolContext from '../SchoolContext';
import { IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { errorToast } from '../../../../toast';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../../../loading';
import request from '../../../../request';
import { delay } from '../../../../utils';


class PrefixEditor extends React.Component {

	static MODES = {
		STUDENT: 'student',
		TEACHER: 'teacher',
	}

	static contextType = SchoolContext;

	state = {
		editing: false,
		prefix: ''
	}

	getPrefixAttributeName() {
		return this.props.mode === PrefixEditor.MODES.STUDENT ? 'student_no_prefix' : 'teacher_no_prefix';
	}

	getCurrentPrefix() {
		const attributeName = this.getPrefixAttributeName();
		return this.context.school[attributeName];
	}

	submit = async () => {

		const txtPrefix = document.getElementById('txt-prefix');
		const { prefix } = this.state;

		if (!prefix) {
			errorToast("Prefix can't be empty");
			return txtPrefix.focus();
		}

		try {

			showLoading();

			const prefixName = this.getPrefixAttributeName();
			const data = { [prefixName]: prefix };
			await request.patch('/api/admin/school', data);

			await this.context.updateSchool(data);
			await this.updateState({ editing: false });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	render() {

		const { editing } = this.state;

		let actions;

		if (editing) {
			actions = <>
				<IconButton size='small' color='primary' onClick={this.submit}>
					<DoneIcon />
				</IconButton>
				<IconButton size='small' color='error' onClick={() => this.updateState({ editing: false })}>
					<CloseIcon />
				</IconButton>
			</>
		} else {
			actions = <IconButton
				onClick={
					async () => {
						const editing = true;
						const prefix = this.getCurrentPrefix();
						this.updateState({ prefix, editing });
						await delay(100);

						const elem = document.getElementById('txt-prefix')
						elem.focus();
						
					}
				}
			>
				<EditIcon />
			</IconButton>
		}

		return <div>
			<div className='text-sm text-gray-500 font-semibold'>
				{ this.props.mode === PrefixEditor.MODES.STUDENT ? 'STUDENT' : 'TEACHER' } NO
			</div>
			<div className='bg-gray-200 rounded grid grid-cols-[1fr,auto]'>
				<div className='h-full flex items-center'>
					<input
						className='px-[20px] w-full outline-none bg-transparent' 
						contentEditable={editing}
						id='txt-prefix'
						onChange={ (e) => this.updateState({ prefix: e.target.value })}
						value={editing ? this.state.prefix : this.getCurrentPrefix()}
						disabled={!editing}
					/>
				</div>
				<div className='h-full flex items-center'>
					{actions}
				</div>
			</div>
		</div>
	}
}

PrefixEditor.propTypes = {
	mode: PropTypes.oneOf(Object.values(PrefixEditor.MODES)).isRequired,
};

export default PrefixEditor;