
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import { Component } from 'react';
import { TIMETABLE_SLOT_TYPES } from '../../../../../shared/constants';
import { errorToast } from '../../../toast';


export default class SlotEditor extends Component {

	state = {
		type: TIMETABLE_SLOT_TYPES.SLOT,
		breakLength: '',
		name: ''
	}

	save = () => {
		
		// check values
		const txtType = document.getElementById('txt-type');
		const txtName = document.getElementById('txt-name');
		const txtBreakLength = document.getElementById('txt-break-length');

		const type = this.state.type;
		const name = txtName?.value;
		const breakLength = txtBreakLength?.value;

		if (!type) {
			errorToast('Type is required');
			return txtType.focus();
		}

		if (type === TIMETABLE_SLOT_TYPES.BREAK) {
			if (!txtName.value) {
				errorToast('Name is required');
				return txtName.focus();
			}

			if (!breakLength) {
				errorToast('Break length is required');
				return txtBreakLength.focus();
			}

		}

		// save
		this.props.close({
			type,
			length: parseInt(breakLength) || undefined,
			name: name || undefined,
		});

	}
	
	componentDidMount() {
		if (this.props.editMode) {
			const { type, length, name } = this.props.slot;
			this.setState({ type, breakLength: length, name });
		}
	}

	render() {
		return <Dialog open>
			<DialogTitle>Slot</DialogTitle>

			<DialogContent>
				<div className='max-w-[400px]'>
					<TextField
						id='txt-type'
						label="Type"
						variant='standard'
						select
						value={this.state.type}
						onChange={e => {
							this.setState({ type: e.target.value });
						}}
						fullWidth
					>
						{
							Object
								.keys(TIMETABLE_SLOT_TYPES)
								.map(
									key => <MenuItem key={key} value={TIMETABLE_SLOT_TYPES[key]}>
										{key}
									</MenuItem>
								)
						}
					</TextField>

					{
						this.state.type === TIMETABLE_SLOT_TYPES.BREAK && <>
							<TextField
								id='txt-name'
								value={this.state.name}
								onChange={e => this.setState({ name: e.target.value })}
								label="Name"
								variant='standard'
								fullWidth
							/>
							<TextField
								id='txt-break-length'
								value={this.state.breakLength}
								onChange={e => this.setState({ breakLength: e.target.value })}
								label="Length (min)"
								variant='standard'
								type="number"
								fullWidth
							/>
						</>
					}
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" size="small" onClick={this.save}>
					SAVE
				</Button>
				<Button size="small" onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}