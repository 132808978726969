
import React from 'react';
import PropTypes from 'prop-types';
import actions from '../../../redux/actions';
import { connect } from 'react-redux';
import { CircularProgress, IconButton, MenuItem, TextField } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';

class ClassPickerUnconnected extends React.Component {

	state = {
		loading: false,
		selectedClass: null,
	}

	fetchClasses = async () => {
		try {
			await this.updateState({ loading: true })
			await actions.fetchClasses();
		} catch {

		} finally {
			await this.updateState({ loading: false })
		}
	}

	componentDidMount() {
		if (!this.props.classes)
			this.fetchClasses();
	}

	render() {

		let menuItems;
		const { classes } = this.props;

		if (this.state.loading) {
			menuItems = <div className='flex justify-center items-center py-2'>
				<CircularProgress size={25} />
				<div className='ml-2 text-gray-600 text-sm'>
					Loading classes
				</div>
			</div>
		} else if (!classes) {
			menuItems = <div>
				Classes not loaded
				<IconButton color='primary' onClick={this.fetchClasses}>
					<Refresh />
				</IconButton>
			</div>
		} else if (!classes.length) {
			menuItems = <div>
				No classes to select
			</div>
		} else {
			menuItems = classes.map(item => {
				return <MenuItem value={item}>
					{item.name}
				</MenuItem>
			})
		}

		return <TextField
			label="Select class"
			size='small'
			fullWidth
			select
			value={this.state.selectedClass}
			onChange={
				async (e) => {
					
					const selectedClass = e.target.value;
					await this.updateState({ selectedClass });

					if (typeof this.props.onSelect === 'function')
						this.props.onSelect(selectedClass);
				}
			}
		>
			{menuItems}
		</TextField>
	}
}

const mapStateToProps = state => ({ classes: state.classes });
const ClassPicker = connect(mapStateToProps)(ClassPickerUnconnected);

ClassPicker.propTypes = {
	onSelect: PropTypes.func.isRequired
};

export default ClassPicker;