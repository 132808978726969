import ChevronRight from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import React from "react";


export default function Feature({ name, details }) {

	const [ expanded, setExpanded ] = React.useState(false);
	const icon = expanded ? <ChevronRight className="rotate-90" /> : <ChevronRight />

	return <div className="grid grid-cols-[auto,1fr]">
		<IconButton onClick={() => setExpanded(!expanded)} className="text-gray-500">
			{icon}
		</IconButton>
		<div className="text-sm font-bold h-full flex items-center text-gray-600">{name}</div>
		<div />
		<div className="text-gray-600">
			{expanded && <ul className="list-disc">
				{details.map((detail, i) => <li key={i} className="text-xs">{detail}</li>)}
			</ul>}
		</div>
	</div>


}