import Refresh from "@mui/icons-material/Refresh";
import { Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from 'prop-types';

function AttendanceList(props) {


	const { students } = props;
	let tableBody;

	if (students.length === 0) {
		tableBody = <TableRow>
			<TableCell colSpan={100}>
				<div className="text-gray-600 text-lg">
					No students <IconButton onClick={props.refresh}>
						<Refresh />
					</IconButton>
				</div>
			</TableCell>
		</TableRow>
	} else {
		tableBody = students.map(student => {
			return <TableRow>
				<TableCell>{student.student_no}</TableCell>
				<TableCell>{student.user?.name}</TableCell>
				<TableCell>{student.user?.surname}</TableCell>
				<TableCell>
					<Checkbox
						value={props.attendanceList.includes(student)}
						onChange={
							e => {
								const present = e.target.checked;
								props.onAttendanceListChange(student, present);
							}
						}
					/>
				</TableCell>
			</TableRow>
		});
	}

	return <Table size="small">
		<TableHead>
			<TableRow>
				<TableCell>STUDENT NO</TableCell>
				<TableCell>NAME</TableCell>
				<TableCell>SURNAME</TableCell>
				<TableCell>PRESENT</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{tableBody}
		</TableBody>
	</Table>
}

AttendanceList.propTypes = {
	refresh: PropTypes.func.isRequired,
	onAttendanceListChange: PropTypes.func.isRequired,
	students: PropTypes.arrayOf(PropTypes.object).isRequired,
	attendanceList: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AttendanceList;

