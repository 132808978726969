import { connect } from "react-redux"


function FooterUnconnected(props) {

	if (props.isPWA || props.currentRoute?.startsWith('/dashboard'))
		return <footer />

	return <footer>
		<div className='bg-gradient-to-br from-white to-[#00F2]'>
			<div className='py-4'>
				<div className='flex justify-center items-center'>
					<div className='text-sm text-gray-500'>
						© {new Date().getFullYear()} <a href='//xavisoft.co.zw' className='text-blue-500'>Xavisoft Digital</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
}


const mapStateToProps = state => {
	return {
		isPWA: state.isPWA,
		currentRoute: state.currentRoute,
	}
}

const Footer = connect(mapStateToProps)(FooterUnconnected);
export default Footer;