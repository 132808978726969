import { Button } from "@mui/material";
import Feature from "./Feature";
import { Link } from "react-router-dom";


export default function Plan({ name, price, features, comingSoon=false }) {

	let comingSoonOrPrice;

	if (comingSoon) {
		comingSoonOrPrice = <div className="bg-[var(--primary)] text-white text-2xl my-4 py-1 font-bold px-4 block rounded-full w-[max-content]">
			coming soon
		</div>
	} else {
		comingSoonOrPrice = <div className="flex my-4">
			<div className="text-4xl font-bold text-[var(--primary)]">${price.toFixed(2)}</div>
			<div className="text-gray-500 ml-2">/ month</div>
		</div>
	}

	return <div className="px-4 py-8 border border-gray-200 rounded-lg shadow-md">
		<div className="h-full grid grid-rows-[1fr,auto]">
			<div>
				<div className="text-2xl font-extrabold text-gray-600">{name}</div>
				{comingSoonOrPrice}
				<div className="mt-4">
					{features.map((feature, i) => <Feature key={i} {...feature} />)}
				</div>
			</div>
			<Button className="mt-4 py-2 px-4 rounded-full" fullWidth variant="contained" component={Link} to="/signup" disabled={comingSoon}>
				Get Started
			</Button>
		</div>
	</div>
}