import ArrowForward from '@mui/icons-material/ArrowForward';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Page from './Page';
import { MetaTags } from 'react-meta-tags';
import MetaTitle from '../components/MetaTitle';


class NotFound extends Page {

   _render() {
      return <>
         <MetaTags>
				<MetaTitle>Not Found</MetaTitle>
			</MetaTags>
         <div className="flex flex-col items-center justify-center h-full">
            <Typography
               className="text-4xl font-bold"
               variant='h2'
               color={"primary"}
            >
               Route Not Found
            </Typography>
            <p className="text-lg my-4 text-gray-600">The requested page could not be found.</p>
            <Button endIcon={<ArrowForward />}>
               <Link to="/">Go to Home</Link>
            </Button>
         </div>
      </>
   }
};

export default NotFound;