import { Button, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import SlotEditor from "./SlotEditor";
import { TIMETABLE_SLOT_TYPES } from "../../../../../shared/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddIcon from "@mui/icons-material/Add";
import { Component } from "react";
import { Confirm } from "notiflix";
import SubjectConstraints from "./SubjectConstraints";
import request from '../../../request';
import { showLoading, hideLoading } from '../../../loading';
import swal from 'sweetalert';
import CancelIcon from '@mui/icons-material/Close';
import CenteredMessage from "../../../components/CenteredMessage";


const SLOT_EDITOR_MODE = {
	ADD: 'add',
	EDIT: 'edit'
}

export default class Timetable extends Component {

	state = {
		slotEditorMode: null,
		slotBeingEdited: null,
		slots: [],
		reserved: [],
		startTime: 7 * 60,
		periodLength: 40,
		subjectConstraintsEditorOpen: false,
		classSubjects: null,
		subjectConstraints: null,
		timetable: null,
		timetableFetched: false,
		editMode: false,
	}

	openSlotEditor = (slotEditorMode=SLOT_EDITOR_MODE.ADD, slotBeingEdited=null) => {
		this.setState({ slotEditorMode, slotBeingEdited });
	}

	closeSlotEditor = (slot) => {
		const update = { slotEditorMode: null }

		if (slot) {
			if (this.state.slotEditorMode === SLOT_EDITOR_MODE.EDIT) {
				const slots = this.state.slots.map(x => x === this.state.slotBeingEdited ? slot : x);
				update.slots = slots;
			} else {
				update.slots = [ ...this.state.slots, slot ];
				update.reserved = [ ...this.state.reserved, Array(5).fill('') ];
			}
		}

		this.setState(update);
	}

	deleteRow = (index) => {
		const slots = this.state.slots.filter((_, i) => i !== index);
		const reserved = this.state.reserved.filter((_, i) => i !== index);
		this.setState({ slots, reserved });
	}

	minuteToTime(minute) {
		const hour = Math.floor(minute / 60)
		minute = minute % 60;
		const hrStr = hour.toString().padStart(2, '0');
		const minStr = minute.toString().padStart(2, '0')

		return `${hrStr}:${minStr}`;
	}

	promptReserveReason = () => {
		return new Promise((resolve, reject) => {
			Confirm.prompt(
				'Activity',
				'Please enter the activity this slot is reserved for',
				'',
				'SAVE',
				'CANCEL',
				(value) => {
					resolve(value);
				},
				() => {
					reject(new Error('Cancelled'));
				})
		})
	}

	openSubjectConstraintsEditor = () => {
		this.setState({ subjectConstraintsEditorOpen: true });
	}

	closeSubjectConstraintsEditor = () => {
		this.setState({ subjectConstraintsEditorOpen: false });
	}

	createSubjectConstraints(classSubjects) {

		const subjectConstraints = [];
		const levelMap = new Map();

		for (const classSubject of classSubjects) {

			const levelNumber = classSubject.class.level;

			let level = levelMap.get(levelNumber);
			if (!level) {

				level = {
					level: levelNumber,
					subjects: [],
				};

				levelMap.set(levelNumber, level);
				subjectConstraints.push(level);

			}

			const subject = classSubject.subject;
			if (level.subjects.find(x => x._id === subject._id))
				continue;

			level.subjects.push({
				...subject,
				slots_per_week: 1,
				slots_per_session: 1,
			});

		}

		return subjectConstraints;
	}

	updateSubjectConstraint = (levelIndex, subjectIndex, update) => {
		
		const subjectConstraints = this.state.subjectConstraints.map((level, i) => {
			if (i !== levelIndex)
				return level;

			const subjects = level.subjects.map((subject, j) => {
				if (j !== subjectIndex)
					return subject;

				return { ...subject, ...update };
			});

			return { ...level, subjects };
		});

		this.setState({ subjectConstraints });
	}

	fetchClassSubjects = async () => {

		try {

			showLoading();

			const res = await request.get('/api/admin/timetable/class-subjects');
			const classSubjects = res.data.class_subjects;

			const subjectConstraints = this.createSubjectConstraints(classSubjects);
			this.setState({ classSubjects, subjectConstraints });

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	submit = async () => {

		try {
			
			showLoading();

			// format payload
			/// constraints
			const constraints = {};

			//// reserved slots
			const reserved_slots = [];

			this.state.reserved.forEach((row, rowIndex) => {
				row.forEach((reservedFor, colIndex) => {
					reservedFor = reservedFor?.trim();

					if (reservedFor) {
						reserved_slots.push({
							day: colIndex + 1,
							slot: rowIndex + 1,
							activity: reservedFor,
						});
					}
				});
			});

			constraints.reserved_slots = reserved_slots;

			//// class subject constraints
			const levelSubjectConstraintsMap = {}

			for (const level of this.state.subjectConstraints) {
				for (const subject of level.subjects) {
					const key = `${level.level}-${subject._id}`;
					levelSubjectConstraintsMap[key] = subject;
				}
			}
			
			constraints.class_subjects = this.state.classSubjects.map(classSubject => {
				const subjectId = classSubject.subject._id;
				const level = classSubject.class.level;

				const key = `${level}-${subjectId}`;
				const levelSubjectConstraints = levelSubjectConstraintsMap[key];

				return {
					_id: classSubject._id,
					slots_per_week: parseInt(levelSubjectConstraints.slots_per_week) || 1,
					slots_per_session: parseInt(levelSubjectConstraints.slots_per_session) || 1,
					cutoff_slot: parseInt(levelSubjectConstraints.cutoff_slot) || undefined,
				}

			});

			/// payload
			const payload = {
				first_slot_starts_at: this.state.startTime,
				slot_length: this.state.periodLength,
				slots: this.state.slots,
				constraints,
			}

			// send request
			const res = await request.post('/api/admin/timetable', payload);

			const { timetable } = res.data;
			this.setState({ timetable, editMode: false });

			swal('Timetable created successfully');

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}

	}

	fetchTimetable = async () => {

		try {
			
			showLoading();

			const params = {};

			if (this.props.studentId)
				params.student = this.props.studentId;

			const res = await request.get('/api/general/timetable', { params });
			
			if (!res.data) {
				// if res.data == null, this means that no timetable is available
				const update = { timetableFetched: true }
				if (this.props.allowEditing)
					update.editMode = true;
				return this.setState(update);
			}

			const { timetable, slots, first_slot_starts_at, slot_length, reserved_slots } = res.data;

			const reservedFormatted = [];

			for (let i = 0; i < slots.length; i++) {
				reservedFormatted.push(Array(5).fill(''));
			}

			reserved_slots.forEach(({ day, slot, activity }) => {
				reservedFormatted[slot-1][day-1] = activity;
			});

			this.setState({
				slots,
				reserved: reservedFormatted,
				startTime: first_slot_starts_at,
				periodLength: slot_length,
				timetable,
				timetableFetched: true,
			});

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		this.fetchTimetable();
	}

	render() {

		if (!this.state.editMode && !this.state.timetable) {

			let message;

			if (this.state.timetableFetched) {
				message = "No timetable created yet";
			} else {
				message = "Failed to load timetable";
			}

			return <div className="h-full grid grid-rows-[1fr,auto]">
				<CenteredMessage
					message={message}
					onRefresh={this.fetchTimetable}
				/>
				<div className="text-right p-1">
					<Button
						variant="contained"
						onClick={() => this.setState({ editMode: true })}
						startIcon={<AddIcon />}
						disabled={!this.props.allowEditing}
					>
						CREATE
					</Button>
				</div>
			</div>
		}

		let startTime = this.state.startTime;
		const tableBody = this.state.slots.map((slot, slotIndex) => {
			const time = this.minuteToTime(startTime);
			const slotDuration = slot.length || this.state.periodLength;
			startTime += slotDuration;

			const cells = [
				<TableCell>
					{time}
				</TableCell>
			]

			if (slot.type === TIMETABLE_SLOT_TYPES.BREAK) {
				cells.push(
					<TableCell colSpan={5} align="center">
						{slot.name || 'BREAK'}
					</TableCell>
				)
			} else {
				for (let day = 1; day <=5; day++) {

					const dayIndex = day - 1;

					if (this.state.editMode) {
						const reservedFor = this.state.reserved[slotIndex][dayIndex]
						const isReserved = !!reservedFor;
						const StartIcon = isReserved ? LockOpenIcon : LockIcon;

						let reserveJSX;

						if (this.state.editMode) {
							reserveJSX = <div className="absolute flex justify-center top-0 left-0 right-0 bottom-0 opacity-0 hover:opacity-100">
								<Button
									variant="contained"
									size="small"
									onClick={async () => {

										let value;

										if (isReserved) {
											value = '';
										} else {
											try {
												value = await this.promptReserveReason();
											} catch (err) {
												return;
											}
										}

										const reserved = this.state.reserved.map((x, i) => {
											if (i === slotIndex) {
												const row = x.slice();
												row[day-1] = value;
												return row;
											}

											return x;
										});

										this.setState({ reserved });
										
									}}
									fullWidth
									className="rounded-none"
									startIcon={<StartIcon />}
								>
									{isReserved ? 'Unreserve' : 'Reserve'}
								</Button>
							</div>
						}

						cells.push(<TableCell className="relative">
							{reserveJSX}
							{isReserved ? reservedFor || 'Reserved' : ''}
						</TableCell>)
					} else {

						const slot = this.state.timetable[dayIndex].slots[slotIndex];
						let jsx;

						if (slot.activity) {
							jsx = slot.activity;
						} else {
							jsx = slot.classes?.map(cls => {

								if (cls.class_subjects.length === 0)
									return '';
								
								const subjects = cls.class_subjects.map(classSubject => {
									return classSubject.subject.name;
								}).join(', ');

								return `${cls.name} - (${subjects})`;
							});
						}

						cells.push(<TableCell>{jsx}</TableCell>);
					}
				}
			}

			if (this.state.editMode) {
				cells.push(
					<TableCell align="right">
						{
							slot.type === TIMETABLE_SLOT_TYPES.BREAK && <IconButton
								size="small"
								variant="outlined"
								color="primary"
								onClick={() => {
									this.openSlotEditor(SLOT_EDITOR_MODE.EDIT, slot);
								}}
							>
								<EditIcon className="text-sm" />
							</IconButton>
						}

						<IconButton
							size="small"
							variant="outlined"
							color="error"
							onClick={() => this.deleteRow(slotIndex)}
						>
							<DeleteIcon className="text-sm" />
						</IconButton>
					</TableCell>
				);
			}


			return <TableRow>
				{cells}
			</TableRow>
		});

		let btnSubmitDisabled = false, btnSubmitTitle = '';

		if (this.state.slots.length === 0) {
			btnSubmitDisabled = true;
			btnSubmitTitle = 'Add at least one slot';
		} else if (!this.state.subjectConstraints) {
			btnSubmitDisabled = true;
			btnSubmitTitle = 'No subject constraints set';
		}

		let actionPanelJSX;

		if (this.state.editMode) {
			actionPanelJSX = <>
				<Button size="small" onClick={() => this.openSlotEditor() } startIcon={<AddIcon />}>
					Add Row
				</Button>

				{
					this.state.slotEditorMode && <SlotEditor
						close={this.closeSlotEditor}
						editMode={this.state.slotEditorMode === SLOT_EDITOR_MODE.EDIT}
						slot={this.state.slotBeingEdited}
					/>
				}

				<span title={btnSubmitTitle} className="inline-block ml-4">
					<Button
						size="small"
						onClick={this.submit}
						variant="contained"
						disabled={btnSubmitDisabled}
					>
						SUBMIT
					</Button>
				</span>

				<IconButton onClick={() => this.setState({ editMode: false })} color="error">
					<CancelIcon />
				</IconButton>
			</>
		} else {
			actionPanelJSX = <Button
				size="small"
				onClick={() => this.setState({ editMode: true })}
				startIcon={<EditIcon />}
				disabled={!this.props.allowEditing}
			>
				EDIT
			</Button>
		}

		return (
			<div className="p-4">
				
				<div className="grid grid-cols-[auto,1fr]">
					<h1 className="text-2xl font-bold">Timetable</h1>
					<div className="flex justify-end">
						<div className="flex items-center">
							<Button variant="text" size="small" onClick={this.openSubjectConstraintsEditor} startIcon={<EditIcon />} disabled={!this.state.editMode}>
								SUBJECT CONSTRAINTS
							</Button>

							{
								this.state.subjectConstraintsEditorOpen && <SubjectConstraints
									close={this.closeSubjectConstraintsEditor}
									fetchClassSubjects={this.fetchClassSubjects}
									subjectConstraints={this.state.subjectConstraints}
									updateSubjectConstraint={this.updateSubjectConstraint}
								/>
							}

							<span className="text-gray-600 pl-4">Start time:</span>
							<TextField
								type="time"
								value={this.minuteToTime(this.state.startTime)}
								onChange={e => {
									const [hour, minute] = e.target.value.split(':').map(x => parseInt(x));
									this.setState({ startTime: hour * 60 + minute });
								}}
								size="small"
								variant="outlined"
								className="h-[40px] ml-2"
								disabled={!this.state.editMode}
							/>
							<span className="text-gray-600 pl-4">Period length (min):</span>
							<TextField
								type="number"
								defaultValue={this.state.periodLength}
								onBlur={e => {
									const txtPeriodLength = e.target;
									const periodLength = parseInt(txtPeriodLength.value) || this.state.periodLength;
									this.setState({ periodLength });

									if (!txtPeriodLength.value)
										txtPeriodLength.value = periodLength;
								}}
								size="small"
								variant="outlined"
								className="w-[80px] h-[40px] ml-2"
								disabled={!this.state.editMode}
							/>
						</div>
					</div>
				</div>

				<Divider className="my-4" />

				<Table size="small" className="[&_td]:border-solid [&_td]:border-[1px] [&_td]:border-[#ccc]">
					<TableHead>
						<TableRow>
							<TableCell>Time</TableCell>
							<TableCell>Monday</TableCell>
							<TableCell>Tuesday</TableCell>
							<TableCell>Wednesday</TableCell>
							<TableCell>Thursday</TableCell>
							<TableCell>Friday</TableCell>
							{
								this.state.editMode && <TableCell></TableCell>
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableBody}
					</TableBody>

				</Table>

				<div className="mt-4 text-right">
					{actionPanelJSX}
				</div>
			</div>
		);
	}
}