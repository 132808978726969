import { useNavigate } from "react-router-dom";

export default function Navigate(props) {

   const redirect = useNavigate();

   if (typeof window.App !== 'object')
      window.App = {};

   window.App.redirect = redirect;

   return <>
      {props.children}
   </>
}