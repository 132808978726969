import { Component } from "react";
import PillLabel from "../../../components/PillLabel";
import { hideLoading, showLoading } from "../../../loading";
import swal from "sweetalert";
import CenteredMessage from "../../../components/CenteredMessage";
import Thumbnail from "../HomeworkTest/Thumbnail";
import request from "../../../request";

export default class Homework extends Component {

	state = {
		homework: null,
		groupedHomework: null,
	}

	groupHomeworksByClassSubject = (homework) => {
		const classSubjects = {};

		homework.forEach(h => {
			const classSubject = h.class_subjects[0];
			const classSubjectId = classSubject._id;

			if (!classSubjects[classSubjectId])
				classSubjects[classSubjectId] = {
					...classSubject,
					homework: []
				};

			classSubjects[classSubjectId].homework.push(h);
		});

		return Object.values(classSubjects);
	}

	fetchHomework = async () => {
		try {

			showLoading();

			const res = await request.get('/api/student/homework/recent');
			const homework = res.data.homework;

			const groupedHomework = this.groupHomeworksByClassSubject(homework);
			this.setState({ homework, groupedHomework });

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading();
		}
	}

	componentDidMount() {
		this.fetchHomework();
	}

	render() {

		let jsx;

		if (!this.state.homework) {
			jsx = <CenteredMessage
				message="Homework not fetched"
				onRefresh={this.fetchHomework}
			/>
		} else if (this.state.homework.length === 0) {
			jsx = <CenteredMessage
				message="No homework available"
			/>
		} else {
			jsx = <>
				<PillLabel>Recent Homework</PillLabel>
				<div className="h-[20px]" />
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					{
						this.state.groupedHomework.map(cs => {
							return <div key={cs._id} className="mb-4 shadow rounded p-4">
								<div className="text-lg text-gray-600 font-bold mb-2">{cs.subject.name}</div>
								
									{cs.homework.map(h => {
										return <div className="mt-2" key={h._id}>
											<Thumbnail {...h} isHomework />
										</div>
									})}
							</div>
						})
					}
				</div>
			</>
		}

		return <div className="h-full">
			{jsx}
		</div>
	}
}