import { Pagination } from "@mui/material";

export default function TabPanelPagination({ itemCount, pageSize, page, onChange, fetch }) {
	return <Pagination
		count={Math.ceil(itemCount / pageSize)}
		page={page}
		onChange={
			async (_, page) => {
				await onChange(page);
				fetch();
			}
		}
	/>
}