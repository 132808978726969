import { ADD_CLASS, DELETE_CLASS, SET_CLASSES, UPDATE_CLASS } from "./constants";

export default function classesReducer(state=null, action) {

	switch (action.type) {
		case SET_CLASSES:
			return action.payload;

		case ADD_CLASS:
			return [ ...state, action.payload ];

		case UPDATE_CLASS:
			const { _id, updates } = action.payload;
			
			return state.map(item => {
				if (item._id === _id)
					return { ...item, ...updates };
				return item;
			});

		case DELETE_CLASS:
			return state.filter(item => item._id !== action.payload);
	
		default:
			return state;
	}

}