import { Component } from "react";
import PillLabel from '../../../../../components/PillLabel';
import { connect } from 'react-redux';
import { Button } from "@mui/material";
import { SCHOOL_SUBSCRIPTION_PLAN_PRICES } from "../../../../../../../shared/constants";
import SubscriptionDialog from "./SubscriptionDialog";


class SubscriptionUnconnected extends Component {

	state = {
		subscriptionDialogOpen: false,
	}

	openSubscriptionDialog = () => {
		this.setState({ subscriptionDialogOpen: true });
	}

	closeSubscriptionDialog = () => {
		this.setState({ subscriptionDialogOpen: false });
	}

	render() {

		const { subscription } = this.props;
		const subscribed = Boolean(subscription);
		const subscriptionExpired = subscribed && (new Date(subscription.expires) < new Date());

		return (
			<div className="m-2 p-2 bg-[var(--primary)] rounded-lg shadow-md">
				
				<PillLabel>Subscription</PillLabel>

				<div className="flex flex-col items-center my-4">
					<div className={`text-white ${subscribed ? 'text-6xl' : 'text-xl'} font-bold font-[monospace]`}>
						{subscribed ? subscription.plan : '{not_subscribed}'}
					</div>
					{
						subscribed && <div className="text-blue-200 text-lg">
							<b>{SCHOOL_SUBSCRIPTION_PLAN_PRICES[subscription.plan].toFixed(2)}</b> per month
						</div>
					}
					{
						subscribed && <div className="text-sm text-white mt-1">
							Expire{subscriptionExpired ? 'd' : 's'} <b>{new Date(subscription.expires).toLocaleString()}</b>
						</div>
					}

					<Button
						variant="contained"
						className="bg-white text-[var(--primary)] mt-4 rounded-full px-8"
						onClick={this.openSubscriptionDialog}
					>
						{
							subscribed ? (
								subscriptionExpired ? 'Renew' : 'Extend'
							) : 'Subscribe'
						}
					</Button>

					{
						this.state.subscriptionDialogOpen && <SubscriptionDialog
							open={this.state.subscriptionDialogOpen}
							close={this.closeSubscriptionDialog}
							plan={subscription?.plan}
						/>
					}
				</div>

			</div>
		)
	}
}

const mapStateToProps = state => {
	const subscription = state.user.school.subscription?.plan && state.user.school.subscription;
	return { subscription };
};

const Subscription = connect(mapStateToProps)(SubscriptionUnconnected);

export default Subscription;
