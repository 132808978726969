import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { Component } from "react";
import request from "../../../../request";
import { errorToast, successToast } from "../../../../toast";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import SearchTeacherOrStudent from "../SearchTeacherOrStudent";


export default class RecordPayment extends Component {

	state = {
		amount: '',
		student: null,
	}

	recordPayment = async () => {

		const { student, amount } = this.state;

		if (!student) {
			document.getElementById('txt-student-search').focus();
			return errorToast('Please select a student');
		}

		if (!amount) {
			document.getElementById('txt-amount').focus();
			return errorToast('Please enter an amount');
		}

		try {

			showLoading();

			await request.post('/api/admin/fees/payments', {
				student: student._id,
				amount
			});

			this.close();
			successToast('Payment recorded');

		} catch (err) {
			swal(err.toString());
		} finally {
			hideLoading();
		}
	
	}

	onStudentSelected = student => {
		return this.updateState({ student });		
	}

	close = () => {
		this.props.close();
	}
	
	render() {

		return (
			<Dialog open>
				<DialogTitle>Record Payment</DialogTitle>

				<DialogContent dividers>
					<div className="grid grid-cols-2 gap-4">
						<SearchTeacherOrStudent
							mode={SearchTeacherOrStudent.MODES.STUDENT}
							onSelect={this.onStudentSelected}
						/>
						<TextField
							id="txt-amount"
							label="Amount"
							variant="outlined"
							size="small"
							type="number"
							value={this.state.amount}
							onChange={e => this.setState({ amount: e.target.value })}
						/>
					</div>
				</DialogContent>

				<DialogActions>
					<Button
						variant="contained"
						size="small"
						onClick={this.recordPayment}
					>
						Record
					</Button>

					<Button
						size="small"
						onClick={this.close}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}