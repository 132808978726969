import { Component } from "react";
import PillLabel from "../../../../components/PillLabel";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";


class SummaryUnconnected extends Component {
	render() {

		const { user } = this.props;
		const { school, student } = user;

		return <div className="shadow-md p-4">

			<PillLabel>ABOUT</PillLabel>

			<div className="md:grid grid-cols-2 gap-4 my-2">
				<div>
					<div className="text-lg text-gray-600 font-bold">{school.name}</div>
					<p className="text-lg text-gray-600 italic">{school.address || '(no address)'}</p>
					<p className="text-lg text-gray-600">{school.email || '(no email)'}</p>
					<p className="text-lg text-gray-600">{school.phone || '(no phone number)'}</p>
				</div>
				<div>
					<div className="grid grid-cols-2 gap-2">
						<span className="text-lg text-gray-600 font-bold">Student No</span>
						<span className="text-lg text-gray-600">{student.student_no}</span>
						<span className="text-lg text-gray-600 font-bold">Class</span>
						<span className="text-lg text-gray-600">{student.class.name}</span>
						<span className="text-lg text-gray-600 font-bold">Level</span>
						<span className="text-lg text-gray-600">{student.class.level}</span>
						<span className="text-lg text-gray-600 font-bold">Fee Status</span>
						<span className={`text-lg text-gray-600 ${student.outstanding_fees > 0 ? 'text-red-600' : 'text-green-600'}`}>
							{student.outstanding_fees > 0 ? `Outstanding (${student.outstanding_fees.toFixed(2)})` : 'Paid'}
						</span>
					</div>
				</div>
			</div>

			<div className="text-right">
				<IconButton color="primary" onClick={() => actions.fetchUser()}>
					<RefreshIcon />
				</IconButton>
			</div>
		</div>
	}
}

const mapStateToProps = state => {
	return {
		user: state.user
	}
}

const Summary = connect(mapStateToProps)(SummaryUnconnected);
export default Summary;