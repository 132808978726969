

import React from 'react';
import PropTypes from 'prop-types';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const FloatingActionButtons = (props) => {

	const buttonConfigs = [
		{
			icon: Add,
			caption: 'Add',
			onClick: props.onAdd,
		},
		{
			icon: Refresh,
			caption: 'Reload',
			onClick: props.onRefresh,
		}
	];

	const actions =  buttonConfigs.map(config => {
		return <SpeedDialAction
			icon={<config.icon />}
			onClick={config.onClick}
			tooltipTitle={config.caption}
		/>
	});

	return <div>
		<SpeedDial
			direction={props.expandingDirection}
			icon={<MoreVertIcon />}
			sx={{ position: 'absolute', bottom: 16, right: 16, color: 'pa' }}
			ariaLabel=''
			color='secondary'
		>
			{actions}
		</SpeedDial>
	</div>;
}

const propTypes = {
	onRefresh: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
	expandingDirection: PropTypes.oneOf([ 'left', 'right', 'down', 'up' ]).isRequired
};

const defaultProps = {
	expandingDirection: 'up',
};

FloatingActionButtons.propTypes = propTypes;
FloatingActionButtons.defaultProps = defaultProps;

export default FloatingActionButtons;