import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import TimetableComponent from '../../../Timetable';

export default function Timetable(props) {

	return <Dialog open fullScreen>
		<DialogContent>
			<TimetableComponent studentId={props.studentId} />
		</DialogContent>
		<DialogActions>
			<Button onClick={props.close}>
				CLOSE
			</Button>
		</DialogActions>
	</Dialog>
}