
import { Component } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeworkIcon from '@mui/icons-material/Assignment';
import TimetableIcon from '@mui/icons-material/Schedule';
import SubjectsIcon from '@mui/icons-material/MenuBook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Dashboard from './Dashboard';
import Timetable from '../Timetable';
import Homework from './Homework';
import Subjects from './Subjects';
import ReportCard from '../ReportCard';


const SIDEBAR_VALUES = {
	DASHBOARD: 'dashboard',
	HOMEWORK: 'homework',
	TIMETABLE: 'timetable',
	SUBJECTS: 'subjects',
	REPORT_CARD: 'report-card',
};


export default class StudentPortal extends Component {

	static title = 'Student Portal';

	static sidebarItems = [
      {
			caption: "Dashboard",
			icon: DashboardIcon,
			value: SIDEBAR_VALUES.DASHBOARD
		},
		{
			caption: "Homework",
			icon: HomeworkIcon,
			value: SIDEBAR_VALUES.HOMEWORK
		},
		{
			caption: "Timetable",
			icon: TimetableIcon,
			value: SIDEBAR_VALUES.TIMETABLE
		},
		{
			caption: "Subjects",
			icon: SubjectsIcon,
			value: SIDEBAR_VALUES.SUBJECTS
		},
		{
			caption: "Report Card",
			icon: AssessmentIcon,
			value: SIDEBAR_VALUES.REPORT_CARD
		},
   ];

	render() {

		let jsx;
		
		switch (this.props.sidebarValue) {
			case SIDEBAR_VALUES.TIMETABLE:
				jsx = <Timetable />
				break;
			case SIDEBAR_VALUES.HOMEWORK:
				jsx = <Homework />
				break;
			case SIDEBAR_VALUES.SUBJECTS:
				jsx = <Subjects />
				break;
			case SIDEBAR_VALUES.REPORT_CARD:
				jsx = <ReportCard />
				break;
			default:
				jsx = <Dashboard />
				break;
		}

		return <div className='h-full p-4'>
			{jsx}
		</div>
  }
}