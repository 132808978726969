

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ReportCardComponent from "../../../ReportCard";


export default function ReportCard(props) {

	const { name, surname } = props.student.user
	const { _id: studentId, school } = props.student;

	return <Dialog open fullScreen>
		<DialogTitle>
			Report Card for {name} { surname}
		</DialogTitle>
		<DialogContent>
			<ReportCardComponent
				studentId={studentId}
				currentTerm={school?.current_term}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.close}>
				CLOSE
			</Button>
		</DialogActions>
	</Dialog>
}