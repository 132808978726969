

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import "quill/dist/quill.snow.css";
import TeacherContext from '../TeacherPortal/TeacherContext';
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../../../loading';
import Refresh from '@mui/icons-material/Refresh';
import { errorToast, successToast } from '../../../toast';
import request from '../../../request';
import { createQuillInstance, delay, isQuillEditorEmpty } from '../../../utils';
import { connect } from 'react-redux';



class HomeworkTestEditorUnconnected extends React.Component {

	static contextType = TeacherContext;

	static MODES = {
		ADD: 'add',
		EDIT: 'edit',
	}

	state = {
		class_subjects: [],
	}

	editorRef = React.createRef();
	classSubjectsRef = React.createRef();

	async initializeEditor() {

		while (!this.editorRef.current) {
			await delay(100);
		}

		if (this.quill)
			return;

		const quill = createQuillInstance(this.editorRef.current);
		this.quill = quill;
		
	}

	fetchClassSubjects = async () => {
		try {
			showLoading();
			await this.context.fetchClassSubjects();
		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	submit = async () => {

		// check fields
		const txtName = document.getElementById('txt-name');
		const name = txtName.value;

		if (!name) {
			errorToast('Name is required');
			return txtName.focus();
		}

		const { class_subjects } = this.state;

		if (!this.isEditMode()) {
			if (class_subjects.length === 0) {
				errorToast('Select at least one class subject');
				return this.classSubjectsRef.current?.focus();
			}
		}

		if (isQuillEditorEmpty(this.quill)) {
			errorToast('No content');
			return this.editorRef.current.focus();
		}

		const content = this.quill.getContents();

		try {

			showLoading();

			const data = {
				name,
				class_subjects: this.isEditMode() ? undefined : class_subjects,
				content: JSON.stringify(content),
			}

			const testsOrHomework = this.isHomework() ? 'homework' : 'tests'
			let url = `/api/teacher/${testsOrHomework}`;

			if (this.isEditMode())
				url += `/${this.props.data._id}`;

			const method = this.isEditMode() ? 'patch' : 'post';

			const res = await request({
				method,
				data,
				url,
			});

			successToast('Successfully saved');

			if (this.isEditMode()) {
				this.props.close({
					name,
					content: data.content,
				});
			} else {
				this.props.close({
					_id: res.data._id,
					name,
					class_subjects: [],
					createdAt: new Date(),
				});
			}

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading();
		}

	}

	isHomework() {
		return this.props.entityType === HomeworkTestEditor.ENTITY_TYPES.HOMEWORK;
	}

	isEditMode() {
		return this.props.mode === HomeworkTestEditor.MODES.EDIT;
	}

	async componentDidMount() {
		
		await this.initializeEditor();

		if (this.isEditMode()) {
			const { content } = this.props.data;
			this.quill.setContents(JSON.parse(content));
		} else {
			if (!this.context.classSubjects)
				this.fetchClassSubjects();
		}
	}

	render() {

		let classSubjects;

		if (this.isEditMode()) {
			classSubjects = <TextField
				fullWidth
				label='Class subjects'
				value={
					this.props.data.class_subjects
						.map(cs => `${cs.subject.name} (${cs.class.name})`)
						.join(', ')
				}
				disabled
			/>
		} else if (this.context.classSubjects) {

			const label = "Class subjects";

			classSubjects = <FormControl fullWidth>
				<InputLabel id="sel-class-subjects-label">{label}</InputLabel>
				<Select
					labelId='sel-class-subjects-label'
					fullWidth
					ref={this.classSubjectsRef}
					multiple
					value={this.state.class_subjects}
					input={<OutlinedInput id="select-multiple-chip" label={label}  />}
					onChange={
						(event) => {
							const class_subjects = event.target.value;
							if (Array.isArray(class_subjects))
								this.updateState({ class_subjects })
						}
					}
				>
					{
						this.context.classSubjects.map(classSubject => {
							return <MenuItem value={classSubject._id} key={classSubject._id}>
								{classSubject.subject.name} ({classSubject.class.name})
							</MenuItem>
						})
					}
				</Select>
			</FormControl>
		} else {
			classSubjects = <>
				Class subjects not loaded
				<IconButton onClick={this.fetchClassSubjects}>
					<Refresh />
				</IconButton>
			</>
		}

		return <Dialog open fullScreen>
			<DialogTitle>
				<div className='grid grid-cols-[1fr,auto]'>
					<div>
						Add {this.isHomework() ? 'Homework' : 'Test'}
					</div>
					<div className='flex items-center'>
						{
							this.props.currentTerm && <div className='inline-block bg-[var(--primary)] text-white text-sm font-bold rounded-full px-4 py-2'>
								<span className='text-blue-200'>Current Term:</span> {this.props.currentTerm.year} - {this.props.currentTerm.term} 
							</div>
						}
					</div>
				</div>
			</DialogTitle>
			<DialogContent dividers>
				<div className='h-full grid grid-rows-[auto,1fr]'>
					<div className='py-4 grid md:grid-cols-2 gap-4'>

						<TextField
							fullWidth
							defaultValue={this.isEditMode() ? this.props.data.name : ''}
							label={`${this.isHomework() ? 'Homework' : 'Test'} name`}
							id='txt-name'
						/>
						
						{classSubjects}
					</div>
					<div className='relative'>
						<div id='div-editor' ref={this.editorRef} />
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={this.submit} size='small'>
					SAVE
				</Button>
				<Button variant='text' onClick={() => this.props.close()} size='small'>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}

const mapStateToProps = state => {
	const currentTerm = state.user?.school?.current_term;
	return { currentTerm }
}

const HomeworkTestEditor = connect(mapStateToProps)(HomeworkTestEditorUnconnected);

HomeworkTestEditor.ENTITY_TYPES = {
	TEST: 'test',
	HOMEWORK: 'homework',
}

HomeworkTestEditor.propTypes = {
	mode: PropTypes.oneOf([ HomeworkTestEditor.MODES.ADD, HomeworkTestEditor.MODES.EDIT ]).isRequired,
	close: PropTypes.func.isRequired,
	entityType: PropTypes.string.isRequired,
	data: PropTypes.object,
};


export default HomeworkTestEditor;