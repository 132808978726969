import { Component } from "react";
import { BILLING_CYCLES, CURRENCIES, SCHOOL_SUBSCRIPTION_PLAN_PRICES, SCHOOL_SUBSCRIPTION_PLANS } from "../../../../../../../shared/constants";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import request from "../../../../../request";
import { showLoading, hideLoading } from "../../../../../loading";
import swal from "sweetalert";
import { errorToast } from "../../../../../toast";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';


export default class SubscriptionDialog extends Component {
	
	state = {
		plan: '',
		billingCycle: BILLING_CYCLES.MONTHLY,
		cycles: 1,
		currency: CURRENCIES.USD,
		payment_link: '',
		amounts: [],
		total: 0,
	}
	
	submit = async () => {
		
		const { plan, billingCycle, cycles, currency } = this.state;

		if (!plan) {
			errorToast('Please select a plan');
			return document.getElementById('txt-plan').focus();
		}
		
		try {

			showLoading();
			
			const data = {
				plan,
				billing_cycle: billingCycle,
				cycles,
				currency,
			}
			
			const res = await request.post('/api/admin/billing/payments', data);
			const { amounts, payment_link, total } = res.data;

			this.setState({ amounts, payment_link, total });

			
		} catch (e) {
			swal('Error', e.message, 'error');
		} finally {
			hideLoading();
		}
		
	}

	componentDidMount() {
		if (this.props.plan) {
			this.setState({ plan: this.props.plan });
		}
	}
	
	render() {
		
		const { plan, cycles } = this.state;

		let content, dialogActions;

		if (this.state.payment_link) {
			content = <div>
				<div className="">
					{
						this.state.amounts.map((item, i) => {
							const { quantity, amount, title } = item;
							return <div key={i} className="text-gray-600 text-sm border-b-solid border-b-[#ccc] border-b-[1px] py-2">
								<div className="font-bold">{title}</div>
								<div>{item.quantity === 1 ? '' : `${quantity} X`} {amount.toFixed(2)}</div>
							</div>
						})
					}
				</div>
				<div className="grid grid-cols-[1fr,auto] mt-4">
					<div className="text-xl text-gray-600">
						<b>{this.state.currency}</b> {this.state.total.toFixed(2)}
					</div>
					<Button startIcon={<EditIcon />} onClick={() => this.setState({ payment_link: '' })} size="small">
						EDIT
					</Button>
				</div>
			</div>

			dialogActions = <Button variant="contained" href={this.state.payment_link} className="mx-4 my-2 w-full rounded-full">
				CONTINUE TO PAY
			</Button>

		} else {

			content = <div className="[&>*]:mb-4">
						
				<div>
					<div className="grid grid-cols-[auto,auto] gap-4">
						<Label>Select plan</Label>
						{
							this.state.plan && <div className="text-sm text-gray-500 text-right">
								<b>{SCHOOL_SUBSCRIPTION_PLAN_PRICES[this.state.plan].toFixed(2)}</b> / month
							</div>
						}
					</div>
					<TextField
						id="txt-plan"
						variant="outlined"
						size="small"
						value={plan}
						onChange={e => this.setState({ plan: e.target.value })}
						select
						fullWidth
					>
						{
							Object
								.values(SCHOOL_SUBSCRIPTION_PLANS)
								.filter((p) => p !== SCHOOL_SUBSCRIPTION_PLANS.COMPLETE)
								.map((plan) => (
									<MenuItem key={plan} value={plan}>
										{plan}
									</MenuItem>
								))
						}
					</TextField>
					
				</div>

				<div>
					<Label>Months</Label>
					<div className="grid grid-cols-[auto,1fr,auto] gap-4">
						<IconButton onClick={() => this.setState({ cycles: Math.max(1, cycles - 1) })}>
							<RemoveIcon />
						</IconButton>
						<div className="flex items-center justify-center h-full rounded-full border-solid border-[1px] border-[#ccc]">
							{cycles}
						</div>
						<IconButton onClick={() => this.setState({ cycles: cycles + 1 })}>
							<AddIcon />
						</IconButton>
					</div>
				</div>

			</div>

			dialogActions = <div className="px-4 py-2 w-full">
				<Button onClick={this.submit} variant="contained" size="large" fullWidth className="rounded-full">
					SUBMIT
				</Button>
			</div>
		}
		
		return <Dialog open>
			<DialogTitle>
				<div className="text-2xl font-bold text-[var(--primary)] relative">
					Subscribe
					<div className="absolute top-0 right-0 bottom-0 flex items-center">
						<IconButton onClick={this.props.close}>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent dividers>
				<div className="min-w-[250px]">
					{content}
				</div>
			</DialogContent>
			<DialogActions>
				{dialogActions}
			</DialogActions>
		</Dialog>
	}
}

function Label({ children }) {
	return <div className="text-sm text-gray-600 font-bold mb-1">{children}</div>
}