import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Component } from "react";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../../../../loading";
import { errorToast, successToast } from '../../../../toast'
import request from "../../../../request";
import SchoolContext from "../SchoolContext";


export default class FeesEditor extends Component {

   state = {
      fees: 0,
   }

   static contextType = SchoolContext;

   submit = async () => {

      const fees = await this.state.fees;

      if (!fees)
         return errorToast('Provide the fees');

      try {

         showLoading();

         await request.patch('/api/admin/school', { fees });
         successToast("Fees updated");

         this.context.updateSchool({ fees});
         this.close();

      } catch (err) {
         swal(err.toString());
      } finally {
         hideLoading();
      }
   }

   close = () => {
      this.props.close();
   }

   componentDidMount() {
      this.setState({ fees: this.context.school.fees });
   }

   render() {

      return <Dialog open>

         <DialogTitle>Set Fees</DialogTitle>

         <DialogContent dividers>
            <TextField
               variant="standard"
               value={this.state.fees}
               type="number"
               onChange={
                  e => {
                     this.setState({ fees: e.target.value })
                  }
               }
            />
         </DialogContent>

         <DialogActions>
            <Button variant="contained" onClick={this.submit} size="small">
               SAVE
            </Button>
            <Button onClick={this.close}>
               CANCEL
            </Button>
         </DialogActions>
            
      </Dialog>
   }
}