import { Divider, IconButton } from "@mui/material";
import Logo from "../Logo";
import OtherLinks from "./OtherLinks";
import SignupAndLogin from "./SignupAndLogin";
import CloseIcon from '@mui/icons-material/Close';


export default function MobileNav(props) {

   const divider = <Divider className="my-4" />;

   return (
      <div className="h-screen w-screen fixed bg-white top-0 left-0 p-4 z-30"> 
         <Logo />
         {divider}
         <OtherLinks closeMobileNav={props.close} />
         {divider}
         <SignupAndLogin closeMobileNav={props.close} />

         <div className="w-full absolute bottom-0 pb-3">
            <div className="flex justify-center">
               <IconButton onClick={props.close}>
                  <CloseIcon />
               </IconButton>
            </div>
         </div>
      </div>
   );

}