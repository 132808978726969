import { Button, Divider } from "@mui/material";
import { Component } from "react";
import NumberCard from "./NumberCard";
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import FeesEditor from "./FeesEditor";
import SchoolContext from "../SchoolContext";
import RecordPayment from "./RecordPayment";
import PrefixEditor from "./PrefixEditor";
import Subscription from "./Subscription";
import { NewTerm } from "./NewTerm";


export default class Dashboard extends Component {

   static contextType = SchoolContext;

   state = {
      feesEditorOpen: false,
      paymentRecorderOpen: false,
      newTermEditorOpen: false,
   }

   canStartNewTerm = () => {
      if (!this.context.school.fees)
         return false;
      return true;
   }

   openFeesEditor = async () => {
      return this.updateState({ feesEditorOpen: true });
   }

   closeFeesEditor = async () => {
      return this.updateState({ feesEditorOpen: false });
   }

   openNewTermEditor = () => {
      this.setState({ newTermEditorOpen: true })
   }

   closeNewTermEditor = (current_term) => {
      if (current_term)
         this.context.updateSchool({ current_term });

      this.setState({ newTermEditorOpen: false });
   }

   openPaymentRecorder = () => { 
      return this.updateState({ paymentRecorderOpen: true });
   }

   closePaymentRecorder = () => {
      return this.updateState({ paymentRecorderOpen: false });
   }

   getYearLastThreeDigits() {
      return new Date().getFullYear().toString().slice(-3);
   }

   render() {

      const divider = <Divider className="my-4" />

      return (
         <div className="p-4">

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
               <NumberCard
                  number={this.context.school.students}
                  text="Students"
               />

               <NumberCard
                  number={this.context.school.classes}
                  text="Classes"
               />

               <NumberCard
                  number={this.context.school.teachers}
                  text="Teachers"
               />

               {
                  this.context.school.fees && <NumberCard
                     number={this.context.school.fees}
                     text="Fees"
                  />
               }
            </div>

            {divider}

            <div className="mt-4">

               <div className="text-lg font-bold text-gray-600">SCHOOL FEES</div>

               <p className="text-gray-600">
                  Set school fees for the term, start accepting fees, record fees payments.
               </p>

               <div className="mt-2">
                  <Button
                     variant="contained"
                     size="small"
                     startIcon={<PriceChangeOutlinedIcon />}
                     onClick={this.openFeesEditor}
                  >
                     SET FEES
                  </Button>

                  <Button
                     variant="contained"
                     size="small"
                     className="ml-2"
                     color="secondary"
                     onClick={this.openNewTermEditor}
                     disabled={!this.canStartNewTerm()}
                  >
                     OPEN NEW TERM
                  </Button>

                  <Button
                     variant="contained"
                     size="small"
                     className="ml-2"
                     color="info"
                     onClick={this.openPaymentRecorder}
                  >
                     RECORD PAYMENT
                  </Button>

                  {
                     this.state.feesEditorOpen && <FeesEditor close={this.closeFeesEditor} />
                  }

                  {
                     this.state.paymentRecorderOpen && <RecordPayment close={this.closePaymentRecorder} />
                  }

                  {
                     this.state.newTermEditorOpen && <NewTerm
                        close={this.closeNewTermEditor}
                        currentTerm={this.context.school.current_term}
                     />
                  }

               </div>
            </div>

            {divider}

            <div className="mt-4">
               <div className="text-lg font-bold text-gray-600">ID PREFIXES</div>
            </div>

            <p className="text-gray-600">
               These prefixes will be used to generate student and teacher numbers that you can use on school IDs. For example:
               <div className="p-4 bg-gray-100">
                  <p>
                     <b>Student No</b>: {this.context.school.student_no_prefix}{this.getYearLastThreeDigits()}0001
                  </p>
                  <p>
                     <b>Teacher No</b>: {this.context.school.teacher_no_prefix}{this.getYearLastThreeDigits()}0002
                  </p>
               </div>
            </p>

            <div className="[&>*]:max-w-[400px] [&>*]:mt-4">
               <div>
                  <PrefixEditor
                     mode={PrefixEditor.MODES.STUDENT}
                  />
               </div>
               <div>
                  <PrefixEditor
                     mode={PrefixEditor.MODES.TEACHER}
                  />
               </div>
            </div>

            {divider}

            <Subscription />

         </div>
      );
   }
}