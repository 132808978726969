
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';

export default function EmptyState({
	title='No items on this list',
	subtitle=``,
	addButtonCaption='Add',
	onAddButtonClicked,
}) {

	if (!subtitle) {
		if (typeof onAddButtonClicked === 'function')
			subtitle = `It's empty here. Click "+ Add New" button to add something`
	}

	return <div className="h-full flex items-center justify-center text-gray-600">
		<div className="w-full max-w-[300px] text-center [&>*]:my-2">
			<div className="text-6xl">
				<DescriptionIcon fontSize="inherit" color="primary" />
			</div>
			<div className="text-2xl font-bold">
				{title}
			</div>
			<div className="text-sm">
				{subtitle}
			</div>
			{
				typeof onAddButtonClicked === 'function' && <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={onAddButtonClicked}>
					{addButtonCaption}
				</Button>
			}
		</div>
	</div>
}