
import { CircularProgress, Popover, TextField } from '@mui/material';
import React from 'react';
import request from '../../../request';

class SearchTeacherOrStudent extends React.Component {

	static MODES = {
		TEACHER: 'teacher',
		STUDENT: 'student',
	}

	state = {
		searching: false,
		searchResults: null,
		showResultsPopover: false,
	}

	searchRef = React.createRef();

	onStudentQueryChange = async e => {

		const search = e.target.value;

		if (!search)
			return this.setState({ student: null });

		await this.updateState({ searching: true, showResultsPopover: true });

		try {

			const mode = this.props.mode;
			const uriComponent = mode === SearchTeacherOrStudent.MODES.STUDENT ? 'students' : 'teachers';
			const res = await request.get(`/api/admin/${uriComponent}/search`, { params: { search } });

			await this.updateState({
				searchResults: res.data.results,
				showResultsPopover: true
			});

		} catch (err) {
			console.error(err);
		} finally {
			await this.updateState({ searching: false });
		}
		
	}

	render() {
		
		let resultsPopover;

		if (this.state.resultsLoading) {
			resultsPopover = <div className="py-[100px] flex justify-center items-center">
				<CircularProgress />
			</div>
		} else if (this.state.searchResults?.length) {
			resultsPopover = <div className="p-4">
				{this.state.searchResults.map(item => {

					const { _id, user } = item;
					const { name, surname } = user;
					const fullName = `${name} ${surname}`;

					return <div key={_id} className="p-2 hover:bg-gray-100 cursor-pointer"
						onClick={ 
							async () => {
								await this.updateState({ showResultsPopover: false });
								await this.props.onSelect(item);
								document.getElementById('txt-item').value = fullName;
								document.getElementById('txt-item').blur();
							}
						}
					>
						{fullName}
					</div>
				})}
			</div>
		} else {
			resultsPopover = <div className="p-4">
				No results
			</div>
		}

		return <div>

				<TextField
					id="txt-item"
					label={this.props.label || (this.props.mode === SearchTeacherOrStudent.MODES.STUDENT? 'Student' : 'Teacher')}
					variant="outlined"
					size="small"
					onChange={this.onStudentQueryChange}
					ref={this.searchRef}
					fullWidth
				/>

				<Popover
					anchorEl={this.searchRef.current} 
					open={this.state.showResultsPopover}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onClose={() => {
						this.setState({ showResultsPopover: false })
					}}
					disableAutoFocus
				>
					{resultsPopover}
				</Popover>
			</div>
	}
}

export default SearchTeacherOrStudent;