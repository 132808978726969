
import './App.css';

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/roboto/100-italic.css';
import '@fontsource/roboto/300-italic.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/roboto/900-italic.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import { Component } from "react";
import { Provider } from 'react-redux';
import store from './redux/store';
import Signup from './pages/Signup';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Navigate from './components/Navigate';
import Dashboard from './pages/Dashboard';
import VerifyAccount from './pages/VerifyAccount';
import { ENTER_KEY_PRESSED } from './constants';
import ErrorBoundary from './components/ErrorBoundary';
import InstallPrompt from './components/InstallPrompt';
import actions from './redux/actions';
import Footer from './components/Footer';
import firebase from './firebase';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Demo from './pages/Demo';

// monkey patching the Component class to add some utility methods
Component.prototype.updateState = function (updates={}) {
	return new Promise(resolve => {
		this.setState(updates, resolve);
	})
}

Component.prototype.overwriteState = function (newState={}) {
	return new Promise(resolve => {
		const oldState = { ...this.state };
		
		for (let key in oldState)
			oldState[key] = undefined;

		const effectiveState = { ...oldState, ...newState }
		this.setState(effectiveState, resolve);

	});
}

// set the window dimensions as css variables
function setDimensions() {
	
	const width = window.innerWidth + 'px';
	const height = window.innerHeight + 'px';

	document.documentElement.style.setProperty('--window-width', width);
	document.documentElement.style.setProperty('--window-height', height);

}

window.addEventListener('resize', setDimensions);
setDimensions();

// listen to enter key press, and dispatch an event
window.addEventListener('keydown', (e) => {
	if (e.key === 'Enter') {
		// no other key is pressed
		if (e.shiftKey) return;
		if (e.ctrlKey) return;
		if (e.altKey) return;
		if (e.metaKey) return;

		// dispatch the event
		const event = new Event(ENTER_KEY_PRESSED);
		window.dispatchEvent(event);
		document.dispatchEvent(event);

	}
});

// set isPWA to true if the app is installed
if (window.location.search.includes('is_pwa=true'))
	actions.setIsPWA();

// initialize firebase
firebase.init();

// main app component
function App() {
  return (
	<ErrorBoundary>
		<Provider store={store}>
			<Router>
				<Navigate>
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/login" element={<Login />} />
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/verify-account/:by/:ref_code" element={<VerifyAccount />} />
						<Route path="/pricing" element={<Pricing />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/demo" element={<Demo />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
					<Footer />
					<InstallPrompt />
				</Navigate>
			</Router>
		</Provider>
	</ErrorBoundary>
  );
}

export default App;
