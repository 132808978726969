
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubjectIcon from '@mui/icons-material/Subject';
import HomeworkIcon from '@mui/icons-material/Assignment';
import { PiExamFill, PiCalendarBold } from "react-icons/pi";
import React from 'react';
import Dashboard from './Dashboard';
import { TeacherContextProvider } from './TeacherContext';
import Homework from './Homework';
import Tests from './Tests';
import Subjects from './Subjects';
import Timetable from '../Timetable';
import GradingIcon from '@mui/icons-material/Grading';
import Gradings from './Gradings';

const SIDEBAR_VALUES = {
   DASHBOARD: 'dashboard',
   TIMETABLE: 'timetable',
	TESTS: 'tests',
	SUBJECTS: 'subjects',
	HOMEWORK: 'homework',
	GRADINGS: 'gradigns',
}

class TeacherPortal extends React.Component {

	static title = 'Teacher Portal';

	static sidebarItems = [
		{
			caption: "Dashboard",
			icon: DashboardIcon,
			value: SIDEBAR_VALUES.DASHBOARD
		},
		{
			caption: 'Timetable',
			icon: PiCalendarBold,
			value: SIDEBAR_VALUES.TIMETABLE
		},
		{
			caption: 'Tests',
			icon: PiExamFill,
			value: SIDEBAR_VALUES.TESTS,
		},
		{
			caption: 'Subjects',
			icon: SubjectIcon,
			value: SIDEBAR_VALUES.SUBJECTS,
		},
		{
			caption: 'Homework',
			icon: HomeworkIcon,
			value: SIDEBAR_VALUES.HOMEWORK,
		},
		{
			caption: 'Gradings',
			icon: GradingIcon,
			value: SIDEBAR_VALUES.GRADINGS,
		},
	];
	
	getCurrentScreen() {
		switch (this.props.sidebarValue) {

			case SIDEBAR_VALUES.HOMEWORK:
				return <Homework />

			case SIDEBAR_VALUES.TESTS:
				return <Tests />

			case SIDEBAR_VALUES.SUBJECTS:
				return <Subjects />

			case SIDEBAR_VALUES.TIMETABLE:
				return <Timetable />

			case SIDEBAR_VALUES.GRADINGS:
				return <Gradings />
		
			default:
				return <Dashboard />;
		}
	}

	render() {
		return <TeacherContextProvider>
			{this.getCurrentScreen()}
		</TeacherContextProvider>
	}
}

export default TeacherPortal;