import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, TextField } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import TeacherContext from "../TeacherContext";
import CenteredMessage from "../../../../components/CenteredMessage";
import { hideLoading, showLoading } from "../../../../loading";
import swal from "sweetalert";
import request from "../../../../request";
import DeleteIcon from "@mui/icons-material/Close";
import AddCircledIcon from '@mui/icons-material/AddCircle';
import { errorToast } from "../../../../toast";
import { getClassLevelLabel } from "../../../../../../shared/utils";


const emptySlot = { test: '', weight: '' };
const defaultWeights = [ { ...emptySlot } ]


class EditorUnconnected extends Component {

	state = {
		level: '',
		subject: '',
		subjects: null,
		tests: null,
		relevantTests: [],
		weights: [ ...defaultWeights ],
	}

	static contextType = TeacherContext;


	save = async () => {
		// validation
		/// presense check
		const { weights } = this.state;

		for (const slot of weights) {
			if (!slot.test)
				return errorToast('Please select a test in every slot');
			if (!slot.weight)
				return errorToast('Please provide a weight in every slot');
		}

		/// uniqueness
		const testIds = weights.map(item => item.test);
		const set = new Set(testIds);

		if (testIds.length > set.size)
			return errorToast('Please make sure that you don\'t select a test more than once');

		/// total
		const total = weights.reduce((acc, item) => acc + item.weight , 0);
		
		if (total !== 100)
			return errorToast('Weights should sum up to 100');

		// submit
		try {

			showLoading();

			let url = '/api/teacher/gradings', method;
			const data = { weights }

			if (this.isEditMode()) {
				url = `${url}/${this.props.data._id}`;
				method = 'PATCH';
			} else {
				const { level, subject } = this.state;

				const class_subjects = this.context.classSubjects
					.filter(item => item.class.level === level && item.subject._id === subject)
					.map(item => item._id);

				data.class_subjects = class_subjects;

				method = 'POST';
			}

			const res = await request({ method, url, data });
			this.props.close(res.data);

		} catch (err) {
			swal(String(err))
		} finally {
			hideLoading();
		}

	}

	extractSubjects = () => {
		const subjectsMap = new Map();

		this.context.classSubjects.forEach(classSubject => {
			const { subject, class: cls } = classSubject;
			const subjectId = subject._id;
			let mappedSubject = subjectsMap.get(subjectId);

			if (!mappedSubject) {
				mappedSubject = { ...subject, levels: new Set() }
				subjectsMap.set(subjectId, mappedSubject);
			}

			mappedSubject.levels.add(cls.level);

		});

		const subjects = Array.from(subjectsMap.values()).map(item => {
			item.levels = Array.from(item.levels).sort();
			return item;
		});

		return this.updateState({ subjects });

	}

	fetchClassSubjects = async () => {

		try {

			showLoading('Fetching subjects...');

			await this.context.fetchClassSubjects();
			await this.extractSubjects();

		} catch (err) {
			swal(String(err));
		} finally {
			hideLoading();
		}
	}

	fetchTests = async (foreground=true) => {
		try {
			if (foreground)
				showLoading('Fetching tests...');

			const { year, term } = this.props.school?.current_term || {}
			const params = { year, term, page: 1, limit: 10000000 }

			const res = await request.get('/api/teacher/tests', { params });
			const tests = res.data.tests;
			await this.updateState({ tests });
			this.extractRelevantTests();

		} catch (err) {
			swal(String(err))
		} finally {
			if (foreground)
				hideLoading();
		}
	}

	extractRelevantTests = async () => {

		if (!this.state.tests)
			return await this.fetchTests();

		const relevantTests = this.state.tests.filter(test => {
			const { class: cls, subject } = test.class_subjects[0];
			if (cls.level === this.state.level && subject._id === this.state.subject)
				return true;
			return false;
		});

		this.updateState({ relevantTests });
	}

	updateSlot = (index=0, update={}) => {
		const weights = [ ...this.state.weights ];
		weights[index] = { ...weights[index], ...update }
		return this.updateState({ weights })
	}

	isEditMode() {
		return Boolean(this.props.data);
	}

	componentDidMount() {

		this.fetchTests(false);

		if (this.context.classSubjects) {
			this.extractSubjects();
		} else {
			this.fetchClassSubjects();
		}

		if (this.isEditMode()) {
			const {
				subject: { _id: subject},
				class: { level }
			} = this.props.data.class_subjects[0];

			const weights = this.props.data.weights.map(w => {
				const {
					weight,
					test: { _id: test }
				} = w;

				return { test, weight }

			})

			this.setState({ subject, level, weights });
		}

	}

	render() {

		const schoolType = this.props.school?.type;
		const gradeOrForm = getClassLevelLabel(schoolType);

		let dialogContent;
		const { subjects } = this.state;
		const isEditMode = this.isEditMode();

		if (!subjects) {
			dialogContent = <div className="w-[400px] max-w-[100%] aspect-square">
				<CenteredMessage
					message={"Subjects not fetched"}
					onRefresh={this.fetchClassSubjects}
				/>
			</div>
		} else if (subjects.length === 0) {
			dialogContent = <div className="w-[400px] max-w-[100%] aspect-square">
				<CenteredMessage
					message={"No subjects available"}
					onRefresh={this.fetchClassSubjects}
				/>
			</div>
		} else {

			let testSelection;
			const { tests, relevantTests, level, subject } = this.state;

			if (!level || !subject) {
				testSelection = testSelection = <div className="p-[50px]">
					<CenteredMessage
						message={"Select subject and level first"}
					/>
				</div>
			} else if (!tests) {
				testSelection = <div className="p-[50px]">
					<CenteredMessage
						message={"Tests not fetched"}
						onRefresh={this.fetchTests}
					/>
				</div>
			} else if (relevantTests.length === 0) {
				testSelection = <div className="p-[50px]">
					<CenteredMessage
						message={"No tests available"}
						onRefresh={this.fetchTests}
					/>
				</div>
			} else {
				testSelection = <div className="p-4">
					
					<Divider className="my-3" />

					<div className="grid grid-cols-[2fr,1fr,auto] gap-4">

						<div className="text-gray-600 text-xs font-bold">
							Test
						</div>
						<div className="text-gray-600 text-xs font-bold">
							Weight (%)
						</div>
						<div>

						</div>

						{
							this.state.weights.map((testSlot, i) => {

								const useLabel = !testSlot.test;

								return <>								
									<TextField
										label={useLabel ? 'Select test' : undefined}
										size="small"
										variant="standard"
										select
										value={testSlot.test}
										onChange={e => this.updateSlot(i, { test: e.target.value })}
									>
										{
											this.state.relevantTests.map(test => {
												return <MenuItem key={test._id} value={test._id}>
													{test.name}
												</MenuItem>
											})
										}
									</TextField>
									<TextField
										label={useLabel ? 'Weight' : undefined}
										placeholder={!useLabel ? "Weight" : undefined}
										size="small"
										variant="standard"
										value={testSlot.weight}
										type="number"
										inputProps={{
											min: 0,
											max: 100,
											className: 'text-right'
										}}
										onChange={e => this.updateSlot(i, { weight: parseFloat(e.target.value) || '' })}
									/>
									<div className="h-full flex items-center">
										<IconButton
											disabled={this.state.weights.length === 1}
											onClick={() => {
												this.setState({
													weights: this.state.weights.filter(item => item !== testSlot)
												})
											}}
											color="error"
											className="text-lg"
										>
											<DeleteIcon fontSize="inherit" />
										</IconButton>
									</div>
								</>
							})
						}
					</div>

					<Divider className="my-4" />

					<div className="text-right">
						<IconButton
							color="primary"
							onClick={() => {
								this.setState({
									weights: [
										...this.state.weights,
										{ ...emptySlot },
									]
								});
							}}
						>
							<AddCircledIcon />
						</IconButton>
					</div>
			</div>
			}

			dialogContent = <div className="w-[400px] max-w-[100%]">
				<div className="grid grid-cols-2 gap-4">
					<TextField
						id="txt-subject"
						label="Select subject"
						size="small"
						fullWidth
						select
						value={this.state.subject}
						onChange={
							e => this.setState({
								subject: e.target.value,
								level: '', // need to reselect level
							})
						}
						disabled={isEditMode}
					>
						{
							subjects.map(({ _id, name }) => {
								return <MenuItem key={_id} value={_id}>
									{name}
								</MenuItem>
							})
						}
					</TextField>
					<TextField
						id="txt-level"
						label={`Select ${gradeOrForm}`}
						size="small"
						fullWidth
						select
						value={this.state.level}
						onChange={
							async e => {
								await this.updateState({
									level: e.target.value,
									relevantTests: [], // tests needs to be reextracted,
									weights: [ ...defaultWeights ], // slots need to be reset
								});

								this.extractRelevantTests();
							}
						}
						disabled={isEditMode}
					>
						{
							subjects
								.find(item => item._id === this.state.subject)?.levels.map(level => {
									return <MenuItem key={level} value={level}>
										{level}
									</MenuItem>
								}) || <MenuItem disabled>
									Select subject first
								</MenuItem>
						}
					</TextField>
				</div>
				{testSelection}
			</div>
		}


		return <Dialog open>
			<DialogTitle>{isEditMode ? "Edit" : "Add"} Grading</DialogTitle>
			<DialogContent dividers>
				{dialogContent}
			</DialogContent>
			<DialogActions>
				<Button variant="contained" size="small" onClick={this.save}>
					SAVE
				</Button>
				<Button size="small" onClick={() => this.props.close()}>
					CANCEL
				</Button>
			</DialogActions>
		</Dialog>
	}
}

const mapStateToProps = state => ({ school: state.user?.school })
const Editor = connect(mapStateToProps)(EditorUnconnected);

export default Editor;