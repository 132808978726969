
import { SET_USER, UPDATE_USER } from "./constants";

export default function userReducer(state=null, action) {

   const { type, payload } = action;

   switch (type) {
      case SET_USER:
         return payload;
      case UPDATE_USER:
         return { ...state, ...payload };
      default:
         return state;
   }
   
}