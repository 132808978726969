import { combineReducers } from "redux";
import currentRouteReducer from "./currentRouteReducer";
import roleReducer from "./roleReducer";
import userReducer from "./userReducer";
import subjectsReducer from "./subjectsReducer";
import classesReducer from "./classesReducer";
import authenticatedReducer from "./authenticatedReducer";
import IsPWAReducer from "./isPWAReducer";

const reducer = combineReducers({
   authenticated: authenticatedReducer,
   classes: classesReducer,
   currentRoute: currentRouteReducer,
   isPWA: IsPWAReducer,
   role: roleReducer,
   subjects: subjectsReducer,
   user: userReducer,
});

export default reducer;