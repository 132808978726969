
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Logo';
import { Component } from 'react';
import MobileNav from './MobileNav';
import SignupAndLogin from './SignupAndLogin';
import { IconButton } from '@mui/material';
import OtherLinks from './OtherLinks';
import { connect } from 'react-redux';


class NavbarUnconnected extends Component {

   state = {
      mobileNavOpen: false
   }

   setMobileNavOpen = (mobileNavOpen) => {
      this.setState({ mobileNavOpen });
   }

   setDimensions = () => {
      const navbar = document.querySelector('nav');
      
      const width = navbar.scrollWidth + 'px';
      const height = navbar.scrollHeight + 'px';

      document.documentElement.style.setProperty('--navbar-width', width);
      document.documentElement.style.setProperty('--navbar-height', height);
   }

   componentWillUnmount() {
      this.resizeOberver.disconnect();
   }

   componentDidMount() {
      const resizeOberver = new window.ResizeObserver(this.setDimensions);
      resizeOberver.observe(document.querySelector('nav'));
      this.resizeOberver = resizeOberver;

      this.setDimensions();
   }

   render() {

      if (this.props.isPWA)
         return <nav />

      const { mobileNavOpen } = this.state;

      let mobileNav;
      if (mobileNavOpen)
         mobileNav = <MobileNav close={() => this.setMobileNavOpen(false)} />

      const isCurrentRouteDashboard = this.props.currentRoute?.startsWith('/dashboard');

      return (
         <>
            <nav className='bg-gradient-to-br from-white to-[#00F2] fixed top-0 left-0 right-0'>
               {
                  isCurrentRouteDashboard ? undefined: <Container className='py-4'>
                     <div className='md:hidden grid grid-cols-[1fr,auto]'>
                        <Logo />
                        <IconButton 
                           onClick={() => this.setMobileNavOpen(true) }
                        >
                           <MenuIcon />
                        </IconButton>
                     </div>
                     <div className="hidden md:grid grid-cols-[auto,1fr,auto]">
                        <div className='h-full flex items-center'>
                           <Logo />
                        </div>
                        <div className='h-full flex justify-center items-center'>
                           <OtherLinks />
                        </div>
                        <div className='h-full flex items-center'>
                           <SignupAndLogin />
                        </div>
                     </div>
                  </Container>
               }
            </nav>
            {!isCurrentRouteDashboard && mobileNav}
         </>
      );
   }
}

const mapStateToProps = state => {
   return {
      currentRoute: state.currentRoute,
      isPWA: state.isPWA
   }
}

const Navbar = connect(mapStateToProps)(NavbarUnconnected);

export default Navbar;