
import * as Sentry from "@sentry/react";

function init() {
   Sentry.init({
		dsn: "https://cb75aa5afa5e199483fe436eca30e4e3@o4504122577059840.ingest.us.sentry.io/4507658557980672",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

function error(err) {
   Sentry.captureException(err);
}

function log(msg) {
   Sentry.captureMessage(msg);
}

let logger;

if (process.env.NODE_ENV === 'production') {
   logger = {
      init,
      error,
      log,
   };
} else {
   logger = console;
}


export default logger;