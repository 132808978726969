

export default function Dashboard() {

	const messages = [
		{
			title: 'Are you a parent?',
			message: 'Ask your child\'s school or your child to add you as a parent.'
		},
		{
			title: 'Are you a teacher?',
			message: 'Ask your school to add you as a teacher.'
		},
		{
			title: 'Are you a school administrator?',
			message: 'Add your school to the system.'
		},
		{
			title: 'Are you a student?',
			message: 'Ask your school to add you as a student.'
		}
	];

	return <div>

		<h1 className="text-gray-600 font-bold text-3xl">
			What to do
		</h1>

		{
			messages.map((message, index) => {
				return <Message key={index} title={message.title} message={message.message} />
			})
		}
	</div>
	
}


function Message({ title, message }) {

	return <div className="p-4 rounded-md shadow-md my-4">
		<h2 className="text-xl text-gray-600 font-semibold">{title}</h2>
		<p className="text-gray-600">{message}</p>
	</div>

}