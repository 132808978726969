import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Component } from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import EmaiIcon from '@mui/icons-material/Email';
import swal from 'sweetalert';
import request from "../../../../../request";
import CenteredMessage from "../../../../../components/CenteredMessage";
import Timetable from "./Timetable"
import Homework from "../../Homework";
import ReportCard from "./ReportCard";

export default class Child extends Component {

	state = {
		student: null,
		loadingStudent: false,
		showingTimetable: false,
		showingReportCard: false,
	}

	fetchData = async () => {
		try {

			this.setState({ loadingStudent: true });

			const res = await request.get(`/api/general/students/${this.props._id}`);
			const student = res.data;

			this.setState({ student });

		} catch (err) {
			swal(String(err));
		} finally {
			this.setState({ loadingStudent: false });
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {

		let subjectsInfo;

		if (this.state.loadingStudent) {
			subjectsInfo = <div className="py-[200px]">
				<div className="text-center">
					<CircularProgress />
				</div>
			</div>
		} else if (this.state.student) {
			subjectsInfo = this.state.student.class_subjects.map((cs, index) => {
				return <div key={index} className="text-gray-600">
					<div className="text-xl font-bold">{cs.subject.name}</div>
					{
						cs.teacher && <div className="text-sm">
							Taught by <span className="font-bold">{cs.teacher.user?.name} {cs.teacher.user?.surname}</span>
						</div>
					}
					{
						typeof cs.average_mark === 'number' && <div className="text-sm">
							<span className={`font-bold ${cs.average_mark >= 50 ? 'text-green-600' : 'text-red-600'}`}>
								{cs.average_mark.toFixed(0)}
							</span> average mark
						</div>
					}
					<Divider className="my-2" />
				</div>
			})
		} else {
			subjectsInfo = <div className="h-[400px]">
				<CenteredMessage
					message={"Subject information not fetched"}
					onRefresh={this.fetchData}
				/>
			</div>
		}


		return <Dialog open fullScreen>
			<DialogTitle>
				<div className="grid grid-cols-[1fr,auto] gap-4">
					<div className="text-2xl font-bold">
						{this.props.user.name} {this.props.user.surname}
					</div>
					<div>
						<Button onClick={() => this.setState({ showingReportCard: true })}>
							REPORT CARD
						</Button>
						{
							this.state.showingReportCard && <ReportCard
								close={() => this.setState({ showingReportCard: false })}
								student={this.props}
							/>
						}
						<Button onClick={() => this.setState({ showingTimetable: true })}>
							TIMETABLE
						</Button>
						{
							this.state.showingTimetable && <Timetable
								studentId={this.props._id}
								close={() => this.setState({ showingTimetable: false })}
							/>
						}
					</div>
				</div>
			</DialogTitle>

			<DialogContent dividers>
				<Container>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<div className="text-lg font-bold">
								{this.props.school.name}
							</div>
							<div className="text-gray-500">
								{this.props.school.address}
							</div>
							<div className="mt-2 [&_span]:pl-1 [&_span]:font-bold">
								<div className="text-gray-500">
									<EmaiIcon /> <span>{this.props.school.email || 'xaviermukodi@gmail.com'}</span>
								</div>
								<div className="text-gray-500">
									<PhoneIcon /> <span>{this.props.school.phone}</span>
								</div>
							</div>
						</div>
						<div>
							<div  className="grid grid-cols-2 gap-4">
								<span className="font-bold">Class</span>
								<span>{this.props.class.name}</span>
								<span className="font-bold">Level</span>
								<span>{this.props.class.level}</span>
								<span className="font-bold">Fees</span>
								<span>
									{
										this.props.outstanding_fees > 0 ?
											<span className="text-red-600">
												{this.props.outstanding_fees.toFixed(2)} outstanding
											</span> :
											<span className="text-green-600">
												Paid
											</span>
									}
								</span>
							</div>
						</div>
					</div>

					<Divider className="my-4" />

					<div className="mt-6">
						<div className="text-xs text-gray-400 font-extrabold mb-2">SUBJECTS</div>
						{subjectsInfo}
					</div>

					<div className="mt-6">
						<div className="text-xs text-gray-400 font-extrabold mb-2">HOMEWORK</div>
						<div className="min-h-[200px]">
							<Homework studentId={this.props._id} />
						</div>
						
					</div>
				</Container>
			</DialogContent>

			<DialogActions>
				<Button onClick={this.props.close}>
					CLOSE
				</Button>
			</DialogActions>
		</Dialog>
	}

}