import { Component } from "react";
import { Sidebar as ProSideBar, Menu, MenuItem, SubMenu, } from "react-pro-sidebar";
import Logo from "../../components/Logo";
import { Divider } from "@mui/material";
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { LOCAL_STORAGE_KEY } from '@xavisoft/auth/constants'
import actions from "../../redux/actions";


export default class Sidebar extends Component {

   renderIcon(Icon) {
      return <Icon
         className="text-xl"
      />
   }

   renderCaption(caption)  {
      return <span className="text-[13px]">
         {caption}
      </span>
   }

   renderExpandIcon({ open }) {
      const Icon = open ? ExpandMore : ChevronRight;
      return <Icon className="text-gray-400" />
   }

   onChange = (value) => {
      this.props.onChange(value);
   }

   renderMenuItems(items, current) {

      return items.map((item, index) => {

         if (item.children) {
            return <SubMenu 
               key={index}
               label={this.renderCaption(item.caption)}
               icon={this.renderIcon(item.icon)}
            >
               {this.renderMenuItems(item.children, current)}
            </SubMenu>
         } else {

            const className = current === item.value ? "bg-[#1972D2] text-white shadow" : "";

            return <MenuItem 
               key={index} 
               onClick={() => this.onChange(item.value)}
               icon={this.renderIcon(item.icon)}
               className={`${className} hover:bg-[#1972D2] hover:text-white hover:shadow`}
            >
               {this.renderCaption(item.caption)}
            </MenuItem>
         }
      });
   }

   logout = () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      actions.setAuthenticated(false);
      window.App.redirect('/login');
   }

   render() {

      const current = this.props.active;

      return <div className="h-full w-full bg-white relative">
         <ProSideBar backgroundColor="#1972D211" className="h-full w-full" >

            <div className="my-6 flex justify-center">
               <Logo _textClass="text-white" />
            </div>

            <Divider />

            <Menu 
               renderExpandIcon={this.renderExpandIcon}
               className="text-gray-500"
            >
               {this.renderMenuItems(this.props.items, current)}
            </Menu>

         </ProSideBar>

         <div className="absolute bottom-0 w-full grid grid-cols-[auto,1fr] p-6 cursor-pointer z-30 hover:bg-gray-200" onClick={this.logout}>
            <div className="px-2 flex items-center">
               <LogoutIcon className="text-gray-500" /> 
            </div>
            <div className="flex items-center text-gray-600 font-bold pl-2" >
               Logout
            </div>
         </div>
      </div>
   }
}
