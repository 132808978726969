import { SET_AUTHENTICATED } from "./constants";


export default function authenticatedReducer(state = false, action) {
	switch (action.type) {
		case SET_AUTHENTICATED:
			return action.payload;
		default:
			return state;
	}
}