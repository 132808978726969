import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TERMS_PER_YEAR } from "../../../shared/constants";

export default function TermNavigator({
	onChange=() => {},
	term={
		year: new Date().getFullYear(),
		term: 1,
	},
	max={
		year: new Date().getFullYear(),
		term: TERMS_PER_YEAR,
	}
}) {

	const changeTermBy = (by=1) => {
		let { term:_term, year } = term;
		_term += by;

		if (_term > TERMS_PER_YEAR) {
			_term = 1;
			year++;
		} else if (_term === 0) {
			_term = TERMS_PER_YEAR;
			year--;
		}

		onChange({ year, term: _term })
	}

	return <div>
		<IconButton onClick={() => changeTermBy(-1)}>
			<ChevronLeftIcon />
		</IconButton>
		<span className="inline-block rounded-full text-sm font-bold px-4 py-1 text-white bg-[var(--primary)]">
			<span className="text-blue-200">Term:</span> {term.year}-{term.term}
		</span>
		<IconButton
			onClick={() => changeTermBy()}
			disabled={
				term.year === max.year &&
				term.term === max.term
			}
		>
			<ChevronRightIcon />
		</IconButton>
	</div>
}